import React, { Component } from 'react';

import './StatusIndicator.css'

export default class StatusIndicator extends Component {

	constructor(props){

		super(props);

		this.state =  {
			status : (typeof this.props.status !=='undefined' && ['danger' , 'warning' , 'success'].includes(this.props.status))? this.props.status : 'none',
			title : (typeof this.props.title !=='undefined' && this.props.title!=='')? this.props.title : '',
			size : (typeof this.props.size !=='undefined' && ['small' , 'medium' , 'big'].includes(this.props.size))? this.props.size : 'small',
			size_dictionary : [
				{ id : 'sm' , name : 'small' },
				{ id : 'md' , name : 'medium' },
				{ id : 'bg' , name : 'big' },
			]
		}

	}

	render(){
		return(
			<div 
				data-variation={this.props.status} 
				data-status="" 
				className={`status-indicator status-indicator--${this.props.status} status-indicator--${this.state.size_dictionary.find(x=> x.name === this.state.size).id}  status-indicator--infinite` }
				data-last-checked-at="" 
				data-frequency="" 
				title={this.state.title}
				>
				<div className={`circle ${this.props.status !=='none' ? 'circle--animated' : ''} circle-main`}></div>
				<div className={`circle ${this.props.status !=='none' ? 'circle--animated' : ''} circle-secondary`}></div>
				<div className={`circle ${this.props.status !=='none' ? 'circle--animated' : ''} circle-tertiary`}></div>
			</div>
		)
	}
}