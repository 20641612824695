import React, { Component } from 'react'

import axios from 'axios'
import moment from 'moment'
import psl from 'psl';
import { Feed } from "feed";


import AppContext from './../../../context/app-context'
import {getIncidentEvents  } from './../../../utils/helper'
import './feed.css'
import Incidents from '../Incidents/Incidents';

export default class PUBLIC_XML_ATOM extends Component{

	static contextType = AppContext
    signal = axios.CancelToken.source();

	constructor(props){
		super(props)
		this.state = {
		 
			statusPageType: 'hosted-subdomain',//hosted-subdomain or custom-domain
			statusPageDomain: '',//'gdfgdfgdf',
			statusPageNativeDomain: '',
			statusPageHomePageLink: null,
			statusPageLogoUrl: null,
			statusPageName: null,
			statusPageChartColor: null,
			statusPageBackgroundColor: null,
            globalStatus: 'up',
			averagePingsDataPerMonitor: {},
			regex_hostedDomain: "^([A-Za-z0-9\-]+)\.(odown.io)",
			regex_hostedDomain_secondary: "^([A-Za-z0-9\-]+)\.(odown.com)",
            isLoading : false ,
            incidentList : [],
			isCompletedLoading : false ,
            isLoading_statusPageInfo : false ,
            notFound: false,
			pings_1d : [],
		}
	}

	/**
	 * 
	 */
	getIncidents(){
		this.setState({
			isLoading : true
		});

		axios
			.get(`${process.env.REACT_APP_API_URL}public/statuspages/${this.state.statusPageDomain}/incidents`, {
				params: {
					//w_data: true,
					type: this.state.statusPageType
				},
				cancelToken: this.signal.token
			})
			.then(res => res.data)
			.then(data => {

				let stateItems = {}

				 
				if(typeof data.events!=='undefined' && data.events!==null && data.events.length > 0) {
							
					data.events.sort((a,b) => moment(b.created_at).unix() - moment(a.created_at).unix())

				}

				stateItems.incidentList = data

				stateItems.isLoading = false;
				stateItems.isCompletedLoading = true;
				this.setState(stateItems);
			})
			.catch(error => {

				let stateItems = {}

				if (axios.isCancel(error)) {
					//console.log('Error: ', err.message); // => prints: Api is being canceled
				} else {
					stateItems.isLoading = false
					stateItems.isCompletedLoading = true;
				}


				this.setState(stateItems);
			})

	}

	getSelectedUrl() {

		return new Promise(async (resolve, reject) => {

			// Get the selected url 
			const url = window.location.href;
			const hostname = new URL(url).hostname;
			let statusPageType = this.state.statusPageType;

			// Get the native domain name from the url (Parse domain without subdomain) 
			var parsedURL = psl.parse(hostname);


			if (!hostname.match(this.state.regex_hostedDomain) && !hostname.match(this.state.regex_hostedDomain_secondary)) statusPageType = 'custom-domain';

			this.setState({
				statusPageType: statusPageType,
				statusPageDomain: hostname,
				statusPageNativeDomain: parsedURL.domain
			}, () => {
				resolve()
			})
		})
	}

	getStatusPageInfo() {


		this.setState({
			isLoading_statusPageInfo: true
		});

		
		const globalStatusPageData = this.context.getStatusPageData();

 
		if(typeof globalStatusPageData!=='undefined' && globalStatusPageData!==null){
			this.setState({

				monitorItems : globalStatusPageData.monitorItems,
				statusPageHomePageLink : globalStatusPageData.statusPageHomePageLink,
				statusPageLogoUrl : globalStatusPageData.statusPageLogoUrl,
				statusPageName : globalStatusPageData.statusPageName,
				statusPageChartColor : globalStatusPageData.statusPageChartColor,
				statusPageBackgroundColor : globalStatusPageData.statusPageBackgroundColor,
				globalStatus : globalStatusPageData.globalStatus,
				pings_1d : globalStatusPageData.pings_1d,
				averagePingsDataPerMonitor : globalStatusPageData.averagePingsDataPerMonitor,
				monitorInfo : globalStatusPageData.monitorInfo,
				isLoading_statusPageInfo : false 
			})

			return 
		}

		axios
			.get(`${process.env.REACT_APP_API_URL}public/statuspages/${this.state.statusPageDomain}`, {
				params: {
					//w_data: true,
					type: this.state.statusPageType
				},
				cancelToken: this.signal.token
			})
			.then(res => res.data)
			.then(data => {

				let stateItems = {}

				if (typeof data.monitors !== 'undefined' && data.monitors !== null && data.monitors.length > 0) {
					stateItems.monitorItems = data.monitors;
					stateItems.statusPageHomePageLink = data.homepage_link;
					stateItems.statusPageLogoUrl = data.logo;
					stateItems.statusPageName = data.name;
					stateItems.statusPageChartColor = data.color;
					stateItems.statusPageBackgroundColor = data.background_color;
					stateItems.allowSubscriptions = data.allow_subscriptions;
					stateItems.allowedChannelsSubs = data.allowed_channel_subs;
					stateItems.statusPageAccessInfo = {
						type : this.state.statusPageType,
						domain : this.state.statusPageDomain,
					}

					// fetch all monitors (one by one )
					let downMonitorCount = 0;
					Promise.all(data.monitors.map(item => {

						if (item.is_down === true) downMonitorCount++;

						// update 24 hours pings ( pings_1d)
						let pings_1d = this.state.pings_1d
						pings_1d.push({
							monitorId: item.id,
							data: item.pings
						});
						stateItems.pings_1d = pings_1d;
						 

						 
					}));


					// validate the global status of all monitors in the current statuspage
					if (downMonitorCount === 0) stateItems.globalStatus = 'up';
					else if (downMonitorCount === data.monitors.length) stateItems.globalStatus = 'down';
					else if (downMonitorCount < data.monitors.length && downMonitorCount > 0) stateItems.globalStatus = 'partial';

				 
					stateItems.monitorInfo = data;
				}

				stateItems.isLoading_statusPageInfo = false;
				this.setState(stateItems , () => {
					// ! Save as a global state 
					this.context.setStatusPageData(stateItems) 
				});
			})
			.catch(error => {

				let stateItems = {}
				stateItems.notFound = true;

				if (axios.isCancel(error)) {
					//console.log('Error: ', err.message); // => prints: Api is being canceled
				} else {
					stateItems.isLoading_statusPageInfo = false
				}


				this.setState(stateItems);
			})
	}

	htmlEntities = (str) => {
		return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
	}

	async componentDidMount(){

		// Get Status Page info
		this.getSelectedUrl()
		.then( async response => {

			this.getStatusPageInfo();
			 // Get the last 30 days incidents :  
			 this.getIncidents();
		})


	   
	   
	}
	
	render(){
			const feed = new Feed({
				title: `${this.state.statusPageName} status - Incident History`,
				description: `${this.state.statusPageName}`,
				link: `${this.state.statusPageDomain}`,
				language: "en", // optional, used only in RSS 2.0, possible values: http://www.w3.org/TR/REC-html40/struct/dirlang.html#langcodes
				copyright: "All rights reserved 2021, Odown",
				updated: new Date(), // optional, default = today
				published : new Date(), 
			 
		  	});
			// add incidents items :
			this.state.incidentList.map( (incident , index) => {

				
				/* ! Example : for each event of an incident 
				<p>
					<small>Sep <var data-var="date">27</var>,
						<var data-var="time">16:04</var> UTC</small><br /><strong>Resolved</strong> - Between 01:40 PM UTC to 3:20 PM
					UTC, we
					experienced an issue with Premium functionality for customers on premium
					subscription for Jira Software, Jira Service Management, and Confluence. The
					issue has been resolved and the service is operating normally.
				</p> */
				let descriptionContent = ``;
				incident.events.map( item => {
					
					descriptionContent += `<p><small>${moment(item.created_at).format('MMM')} <var data-var="date">${moment(item.created_at).format('DD')}</var>,<var data-var="time">${moment(item.created_at).format('HH:mm')}</var> UTC</small><br /><strong>${getIncidentEvents(item.type)}</strong> ${item.message}</p>`;
				})

				feed.addItem({
					title: incident.name,
					id: `https://${this.state.statusPageDomain}/incidents/${incident.id}`,
					link: `https://${this.state.statusPageDomain}/incidents/${incident.id}`,
					description: (descriptionContent),
					date : new Date(incident.created_at)
					
				  });
			} )  


			
			 
		return(
			<pre className="rss-feed-content" >
				{
				
				feed.atom1()
				}
			</pre>
		)		  
		 
	}
}