import React, { Component } from 'react'

import axios from 'axios'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import Favicon from 'react-favicon';
import psl from 'psl';
import { Link } from 'react-router-dom'

import LoadingSpinner from '../../Layout/LoadingSpinner/LoadingSpinner'
import Navbar from '../../Layout/Navbar/Navbar'
import StatusIndicator from '../../Layout/StatusIndicator/StatusIndicator';
import Footer from '../../Layout/Footer/Footer'

// Invoke the global state
import AppContext from './../../../context/app-context'
// import icons 
import { ReactComponent as IncidentIcon } from './../../../assets/icons/flash.svg'

export default class GenericNotFound extends Component{
    static contextType = AppContext;
	signal = axios.CancelToken.source();

	constructor(props) {
		super(props);
		this.state = {
			statusPageType: 'hosted-subdomain',//hosted-subdomain or custom-domain
			statusPageDomain: '',//'gdfgdfgdf',
			statusPageNativeDomain: '',
			statusPageHomePageLink: null,
			statusPageLogoUrl: null,
			statusPageName: null,
			statusPageChartColor: null,
			statusPageBackgroundColor: null,
			isLoading_statusPageInfo: false,
			notFound: false,
			monitorItems: [],

			monitorInfo: null,
			isLoadingMonitors: {},
			monitorChats_last24h: {},
			monitorChats_last12Months: {},
			availableDateRanges: [
				{
					name: "12 months",
					handle : '12months' ,
					nb_days: 365,
					selected: false
				},
				{
					name: "3 months",
					handle : '3months' ,
					nb_days: 91,
					selected: false
				},
				{
					name: "1 month",
					handle : '1month' ,
					nb_days: 30,
					selected: false
				},
				{
					name: "1 week",
					handle : '1week' ,
					nb_days: 7,
					selected: false
				},
				{
					name: "24 hours",
					handle : '24hours' ,
					nb_days: 1,
					selected: true
				}
			],

			pings_1d: [],
			pings_7d: [], // last 7 days 
			pings_30d: [], // last 30 days 
			pings_91d: [], // last 3 months 
			pings_365d: [], // last year
			test: false,
			globalStatus: 'up',
			averagePingsDataPerMonitor: {},
			regex_hostedDomain: "^([A-Za-z0-9\-]+)\.(odown.io)",
			regex_hostedDomain_secondary: "^([A-Za-z0-9\-]+)\.(odown.com)",
			allowSubscriptions : false ,
			allowedChannelsSubs : [] , 
			activeRanges : ['12months' , '3months' , '1month' , '1week' , '24hours'],
			chart_parameters : {},
			showOpenIncidents: false ,


		};

		 
		this.getSelectedUrl = this.getSelectedUrl.bind(this)

	}

	/**
	 * 
	 */
	getStatusPageInfo() {


		this.setState({
			isLoading_statusPageInfo: true
		});

		const globalStatusPageData = this.context.getStatusPageData();

	 
		if(typeof globalStatusPageData!=='undefined' && globalStatusPageData!==null){
			this.setState({

				monitorItems : globalStatusPageData.monitorItems,
				statusPageHomePageLink : globalStatusPageData.statusPageHomePageLink,
				statusPageLogoUrl : globalStatusPageData.statusPageLogoUrl,
				statusPageName : globalStatusPageData.statusPageName,
				statusPageChartColor : globalStatusPageData.statusPageChartColor,
				statusPageBackgroundColor : globalStatusPageData.statusPageBackgroundColor,
				globalStatus : globalStatusPageData.globalStatus,
				pings_1d : globalStatusPageData.pings_1d,
				averagePingsDataPerMonitor : globalStatusPageData.averagePingsDataPerMonitor,
				monitorInfo : globalStatusPageData.monitorInfo,
				isLoading_statusPageInfo : false,
				allowSubscriptions : globalStatusPageData.allowSubscriptions,
				allowedChannelsSubs : globalStatusPageData.allowedChannelsSubs,
				activeRanges : globalStatusPageData.activeRanges,
				chart_parameters : globalStatusPageData.chart_parameters,
				showOpenIncidents : globalStatusPageData.showOpenIncidents,
				availableDateRanges : globalStatusPageData.availableDateRanges,
				statusPageNativeDomain : globalStatusPageData.statusPageNativeDomain,
			})

			Promise.all(globalStatusPageData.monitorItems.map(item => {
 		 
				this.getMonitorResults(item.id, 365);
			}));

			return 
		}


		axios
			.get(`${process.env.REACT_APP_API_URL}public/statuspages/${this.state.statusPageDomain}`, {
				params: {
					//w_data: true,
					type: this.state.statusPageType
				},
				cancelToken: this.signal.token
			})
			.then(res => res.data)
			.then(data => {

				
				let stateItems = {}

				if (typeof data.monitors !== 'undefined' && data.monitors !== null && data.monitors.length > 0) {
					stateItems.monitorItems = data.monitors;
					stateItems.statusPageHomePageLink = data.homepage_link;
					stateItems.statusPageLogoUrl = data.logo;
					stateItems.statusPageName = data.name;
					stateItems.statusPageChartColor = data.color;
					stateItems.statusPageBackgroundColor = data.background_color;
					stateItems.allowSubscriptions = data.allow_subscriptions;
					stateItems.activeRanges = Object.keys(data.chart_options).filter(x => data.chart_options[x].is_active === true);
					stateItems.chart_parameters = data.chart_options;
					stateItems.showOpenIncidents = data.display_open_incidents;
					stateItems.allowedChannelsSubs = data.allowed_channel_subs;
					stateItems.statusPageAccessInfo = {
						type : this.state.statusPageType,
						domain : this.state.statusPageDomain,
					}

					 


					 
					stateItems.monitorInfo = data;

					// ? Change the default selected range
					let tryCount = 0 ; 
					// console.log('Before stateItems.availableDateRanges : ' , this.state.availableDateRanges);
					// console.log('Before activeRanges : ' , stateItems.activeRanges);
					 

					// console.log('stateItems.availableDateRanges : ' , stateItems.availableDateRanges);

					// ? if the status page settings has a homepage url then we extract the native domain 
					// ? name from it else we let the preview native domain name detecter  
					if(typeof data.homepage_link!='undefined' && data.homepage_link!==null){

						const url = data.homepage_link
						// console.log('url : ' , url )
						const hostname = new URL(url).hostname;
						// console.log('hostname : ' , hostname )
						
						// Get the native domain name from the url (Parse domain without subdomain) 
						var parsedURL = psl.parse(hostname);
						// console.log('parsedURL : ' , parsedURL )
						stateItems.statusPageNativeDomain = parsedURL.domain;
					}


				}

				stateItems.isLoading_statusPageInfo = false;
				this.setState(stateItems,  () => {
					// ! Save as a global state 
					this.context.setStatusPageData(stateItems) 
				});
				 
			})
			.catch(error => {

				let stateItems = {}
				stateItems.notFound = true;

				if (axios.isCancel(error)) {
					//console.log('Error: ', err.message); // => prints: Api is being canceled
				} else {
					stateItems.isLoading_statusPageInfo = false
				}


				this.setState(stateItems);
			})
	}


    /**
	 * 
	 * @returns 
	 */
	getSelectedUrl() {

		return new Promise(async (resolve, reject) => {

			// Get the selected url 
			const url = window.location.href;
			const hostname = new URL(url).hostname;
			let statusPageType = this.state.statusPageType;

			// Get the native domain name from the url (Parse domain without subdomain) 
			var parsedURL = psl.parse(hostname);


			if (!hostname.match(this.state.regex_hostedDomain) && !hostname.match(this.state.regex_hostedDomain_secondary)) statusPageType = 'custom-domain';

			this.setState({
				statusPageType: statusPageType,
				statusPageDomain: hostname,
				statusPageNativeDomain: parsedURL.domain
			}, () => {
				resolve()
			})
		})
	}




	componentDidMount() {
		// Get Status Page info
		this.getSelectedUrl()
		.then( response => {

			this.getStatusPageInfo();
		})
		// 
	}

    render(){
        let { averagePingsDataPerMonitor, statusPageBackgroundColor } = this.state;

        return (
            <React.Fragment>
				<Favicon url={`https://s2.googleusercontent.com/s2/favicons?domain=https://${this.state.statusPageNativeDomain}`} />
				<Helmet>
					<title>{`${typeof this.state.statusPageName !== 'undefined' && this.state.statusPageName !== null ? this.state.statusPageName : 'Odown'} Status`}</title>
				</Helmet>
				<div className="global-header" style={{ backgroundColor: statusPageBackgroundColor }} >
					{this.state.notFound === false && this.state.isLoading_statusPageInfo === false &&
						<Navbar
							logo_url={this.state.statusPageLogoUrl}
							homepageLink={this.state.statusPageHomePageLink}
							name={this.state.statusPageName}
							backgroundColor={statusPageBackgroundColor}
							allowSubscriptions={this.state.allowSubscriptions}
							allowedChannelsSubs={this.state.allowedChannelsSubs}
							// statusPageDomain={this.state.statusPageDomain}
							// statusPageType={this.state.statusPageType}  
							/>
					}
					{
						this.state.notFound === false
						&& <div className="header-wrapper">
							<div className="header-content">
								<div className="container">
									<div className={`white-card status-wrapper ${this.state.isLoading_statusPageInfo ? '' : (this.state.globalStatus === 'down' ? 'is-down' : (this.state.globalStatus === 'partial' ? 'is-partial-down' : 'is-up'))}`}>
										{
											this.state.isLoading_statusPageInfo
												? <div className="loading-wrapper"><LoadingSpinner /></div>
												:
												<>
													<div className="status--icon_flag">
														<StatusIndicator 
															status={
																this.state.globalStatus === 'up'
																? 'success'
																: (
																	this.state.globalStatus === 'partial'
																	? 'warning'
																	: 'danger'
																)
															}
															title={``}
															size={`medium`} />
													</div>
													<div className="status--tx-content">
														<div className="status--title">Current status</div>
														<div className="status--global_state_vl" >
															{
																this.state.globalStatus === 'down'
																	? 'All services are down'
																	: (
																		this.state.globalStatus === 'partial'
																			? 'Some services are down'
																			: 'All services are online'
																	)

															}
														</div>
														<div className="status--current-time">
															{
																`As of ${moment().format('DD MMMM YYYY, hh:mm A')}`
															}
														</div>
													</div>
													<div className="incident-link" >
														<Link to={`/incidents`} ><IncidentIcon /> Incident History</Link></div>
												</>
										}
									</div>
								</div>
							</div>
							<div className="header-bottom-polygon" >
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"  >
								<polygon fill="white" points="0,100 100,0 100,100" ></polygon>
							</svg>	
							</div>		
						</div>
					}
				</div>
                <div className="body-wrapper">
					<div className="body-content">
						<div className="container">
                            <div className="">404 ERROR PAGE</div>
                        </div>
					</div>
				</div>
				<Footer />	 
			</React.Fragment>
 

        )
    }
}