import React, { Component } from 'react'


import './LoadingSpinner.css'
export default class LoadingSpinner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position : (typeof this.props.position !=='undefined' && this.props.position !=='') ? this.props.position : '',
            size : (typeof this.props.size !=='undefined' && this.props.size !=='') ? this.props.size : '',
        }
    }

    render() {
        return (
            <div className={`spinner ${this.state.position} ${this.state.size}`} ></div>
        )
    }
}
