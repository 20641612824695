import { Component } from "react";
import {Link} from 'react-router-dom'

export default class Footer extends Component {

	constructor(props) {
		super(props);
		this.state = {

		}
	}

	render() {
		return (
			<div className="footer-wrapper">
				<div className="footer-content">
					<div className="container">
						<div className="nav-items">
							<ul>
								<li>
									<Link to={`/incidents`}>Incident History</Link>
								</li>
							</ul>
						</div> 
						<div className="powered">
							<a href={`https://${process.env.REACT_APP_DOMAIN_NAME}`} target="_blanc">
								Powered by <span className="by">Odown</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}