import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import GA from "./components/Tracking/GoogleAnalytics";

import GlobalState from './context/GlobalState';

import MainStatusPage from "./components/Root/MainStatusPage";
import Incidents from "./components/Root/Incidents/Incidents";
import IncidentDetails from "./components/Root/Incidents/incident-details";
import EmailConfirmSubscritpion from "./components/Root/SubscriberProcessing/EmailConfirmSubscritpion";
import UnsubscribeProcess from "./components/Root/SubscriberProcessing/UnsubscribeProcess";

// 404 page
import GenericNotFound from "./components/Root/Generic/GenericNotFound";


import PUBLIC_RSS from './components/Root/PublicFeed/history.rss'
import PUBLIC_XML_ATOM from './components/Root/PublicFeed/history.atom'




// import app css
import "./App.css";

export default class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {}

	render() {
		return (
			<GlobalState>
				<BrowserRouter>
					<div id="app" className="appContainer">
						<div className="main-wrapper">
							<main className="mainContainer">
								{GA.init({}, "G-6GTBLHT6JR", false) && <GA.RouteTracker />}
								<Switch>
									<Route component={MainStatusPage} exact path="/" />
									<Route component={Incidents} exact path="/incidents" />
									<Route component={IncidentDetails} exact path="/incidents/:incidentId" />
									<Route component={EmailConfirmSubscritpion} exact path="/subscriptions/confirm/:emailSubscriptionToken" />
									<Route component={UnsubscribeProcess} exact path="/subscriptions/unsubscribe/:token" />
									<Route component={PUBLIC_RSS}  exact path="/history.rss"/>
									<Route component={PUBLIC_XML_ATOM}  exact path="/history.atom"/>
									<Route component={GenericNotFound} />
								</Switch>
							</main>
						</div>
					</div>
				</BrowserRouter>
			</GlobalState>
		);
	}
}
