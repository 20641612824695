import React, { Component } from 'react'

import AppContext from './app-context'
 

export default class GlobalState extends Component {
	state = {

		statusPageData : null ,
	};

	constructor() {
		super()

		
		this.getStatusPageData  = this.getStatusPageData.bind(this)
		this.setStatusPageData  = this.setStatusPageData.bind(this)
		
	}
	



	getStatusPageData(){
		return this.state.statusPageData;
	}

	setStatusPageData(data){

		this.setState({
			statusPageData : data
		})
	}

	 

	componentDidMount() {

		 
	}


	render() {
		 
		return (
			<AppContext.Provider 
				value={{
					// isAuth: this.state.isAuth,
					// currentUserData : this.state.currentUserData,
					// setCurrentUserData : this.setCurrentUserData,
					// getCurrentUser : this.getCurrentUser,
					// emptyCurrentUser : this.emptyCurrentUser,
					// cleanUserAccount : this.cleanUserAccount,
					// accountData : this.state.accountData,
					// getCurrentAccountData : this.getCurrentAccountData,
					// emptyCurrentAccount: this.emptyCurrentAccount,
					// login : this.login,
					// logout : this.logout,
					// hiddenLeftSideBar : this.state.hiddenLeftSideBar,
					// hideLeftSideBar : this.hideLeftSideBar,
					// showLeftSideBar : this.showLeftSideBar
					getStatusPageData : this.getStatusPageData,
					setStatusPageData : this.setStatusPageData,

				}} >
				{this.props.children}
			</AppContext.Provider>
		);
	}
}