import React, { PureComponent } from 'react';
import moment from 'moment'
import {
	ResponsiveContainer ,AreaChart, Area,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, BarChart,Bar,Cell, Legend
} from 'recharts';
import { Link } from 'react-router-dom'
// css 
import "./Recharts.css"
// load icons 
import { ReactComponent as IncidentIcon } from './../../../assets/icons/flash.svg'
import { ReactComponent as MaintenanceIcon } from './../../../assets/icons/cone.svg'

const CustomTooltip__responsetime = ({ active, payload, label }) => {

	 
	 
	if (active) { 
		var label_content = "";
		 
		if (typeof label === 'undefined' || label === null || label === 0 || isNaN(label)===false) {
			
			label_content = (typeof payload !== 'undefined' && payload !== null && payload.length > 0) ? moment(payload[0].date).format("DD MMM YYYY HH:mm:ss") : "00:00:00";

		} else {
			if (moment(label).utc().hours()===0 && moment(label).utc().minutes()===0 ){
				// show only the days  
				label_content = moment(label).format("MMM DD, YYYY") 
			}else{
				label_content = moment(label).format("MMM DD, YYYY hh:mm A") 
			}
		}
	  	return (
			<div className="custom-tooltip uptime__chart-tooltip">
				<span className="label">{label_content}</span>
				<span className="value">{`Response Time : ${(typeof payload!=='undefined' && payload!==null && payload.length > 0) ? payload[0].value : 0} ms`}</span>
			</div>
		);
	}
  
	return null;
};
const CustomTooltip__uptime = ({ active, payload, label }) => {
	 
	if (active) { 
		var label_content = "";
		 
		if (typeof label === 'undefined' || label === null || label === 0 || isNaN(label)===false) {
			
			label_content = (typeof payload !== 'undefined' && payload !== null && payload.length > 0) ? moment(payload[0].date).format("DD MMM YYYY HH:mm:ss") : "00:00:00";

		} else {
			if (moment(label).utc().hours()===0 && moment(label).utc().minutes()===0 ){
				// show only the days  
				label_content = moment(label).format("MMM DD, YYYY") 
			}else{
				label_content = moment(label).format("MMM DD, YYYY hh:mm A") 
			}
		}
		// console.log(payload);
		// Prepare the outage delay 
		let outageDelay = {};
		let outageTime = 0;
		 
		if(typeof payload!=='undefined' && payload!==null && payload.findIndex(x=> x.name==='outage') >= 0){
			outageTime = payload.find(x=> x.name==='outage').value;
			outageDelay = moment.duration(outageTime*3600,  'seconds')._data;
			 
		}
	  	return (
			<div className="custom-tooltip uptime__chart-tooltip">
				<span className="label">{label_content}</span>
				<span className="value custom">
					<span className="uptime-metric">
						<label>Uptime : </label>{`${(typeof payload!=='undefined' && payload!==null && payload.length > 0) ? payload.find(x=> x.name==='value').value : 0} %`}</span>
					{
						typeof payload!=='undefined' && payload!==null && payload.findIndex(x=> x.name==='outage') >= 0 && 
						(
							outageTime > 0 
							? <span className="outage-delay">
								<label>Outage : </label>{
								`${outageDelay.days > 0 ? `${outageDelay.days} days ` : ''} ${outageDelay.hours > 0 ? `${outageDelay.hours} hrs ` : ''} ${outageDelay.minutes > 0 ? `${outageDelay.minutes} min ` : ''}`
								}</span>
							: <span className="outage-delay">
								<label>Outage : </label>No downtime recorded on this day.</span>)
					}
					{
						typeof payload!=='undefined' && payload!==null && payload.findIndex(x=> x.name==='outage') >= 0 && payload.find(x=> x.name==='outage').payload.incidents?.length > 0 && 
						<span className="related-incidents" >
							<label htmlFor="">RELATED</label>
							<ul className="related-events-list">
								{
									payload.find(x=> x.name==='outage').payload.incidents.map( (incidentItem , index) => {
										return (
											<li key={index} className="related-event">
												<Link className="related-event-link" target="_blank" to={`/incidents/${incidentItem.id}`}>
													{
													incidentItem.is_scheduled === true 
														? <MaintenanceIcon /> 
														: <IncidentIcon />
													}
													<span>{incidentItem.name}</span></Link>
											</li>
										)
									})	
								}
							</ul>
						</span>
					}
				</span>
			</div>
		);
	}
  
	return null;
};
const CustomTooltip__uptime_bars = ({ active, payload, label }) => {
	 
	if (active) { 
		var label_content = "";
		 
		if (typeof label === 'undefined' || label === null || label === 0 || isNaN(label)===false) {
			
			label_content = (typeof payload !== 'undefined' && payload !== null && payload.length > 0) ? moment(payload[0].date).format("DD MMM YYYY HH:mm:ss") : "00:00:00";

		} else {
			if (moment(label).utc().hours()===0 && moment(label).utc().minutes()===0 ){
				// show only the days  
				label_content = moment(label).format("MMM DD, YYYY") 
			}else{
				label_content = moment(label).format("MMM DD, YYYY hh:mm A") 
			}
		}
		 
		// Prepare the outage delay 
		let outageDelay = {};
		let outageTime = 0;
		// console.log(payload.find(x=> x.name==='outage'));
		if(typeof payload!=='undefined' && payload!==null && payload.findIndex(x=> x.name==='value') >= 0){
			outageTime = payload.find(x=> x.name==='value').payload.outage;
			outageDelay = moment.duration(outageTime*3600,  'seconds')._data;
			 
		}
		 
	  	return (
			<div className="custom-tooltip uptime__chart-tooltip">
				<span className="label">{label_content}</span>
				<span className="value custom">
					<span className="uptime-metric">
						<label>Uptime : </label>{`${(typeof payload!=='undefined' && payload!==null && payload.length > 0) ? payload.find(x=> x.name==='value').payload.uptime : 0} %`}</span>
					{
						typeof payload!=='undefined' && payload!==null && payload.findIndex(x=> x.name==='value' && x.payload.outage > 0) >= 0 && 
						(
							outageTime > 0 
							? <span className="outage-delay">
								<label>Outage : </label>{
								`${outageDelay.days > 0 ? `${outageDelay.days} days ` : ''} ${outageDelay.hours > 0 ? `${outageDelay.hours} hrs ` : ''} ${outageDelay.minutes > 0 ? `${outageDelay.minutes} min ` : ''}`
								}</span>
							: <span className="outage-delay">
								<label>Outage : </label>No downtime recorded on this day.</span>)
					}
					{
						typeof payload!=='undefined' && payload!==null && payload.find(x=> x.name==='value').payload.incidents?.length > 0 && 
						<span className="related-incidents" >
							<label htmlFor="">RELATED</label>
							<ul className="related-events-list">
								{
									payload.find(x=> x.name==='value').payload.incidents.map( (incidentItem , index) => {
										return (
											<li key={index} className="related-event">
												<Link className="related-event-link" target="_blank" to={`/incidents/${incidentItem.id}`}>
													{
													incidentItem.is_scheduled === true 
														? <MaintenanceIcon /> 
														: <IncidentIcon />
													}
													<span>{incidentItem.name}</span></Link>
											</li>
										)
									})	
								}
							</ul>
						</span>
					}
				</span>
			</div>
		);
	}
  
	return null;
};
const CustomLegend__uptime_bars = ({  payload , uptimeValue , daysCount }) => {
 
 
 
	return(
		<div className="custom-legend" >
			<div className="legend-item light legend-item-date-range">
				<span className="availability-time-line-legend-day-count">{daysCount === 91 ? 90 : daysCount} days ago</span>
			</div>	
			<div className="spacer"></div>
			<div className="legend-item legend-item-uptime-value">
				<span>{uptimeValue}</span>
				<span data-span="uptime-percent">% uptime</span>
			</div>	
			<div className="spacer"></div>
			<div className="legend-item light legend-item-date-range">
				<span className="availability-time-line-legend-day-count">Today</span>
			</div>	
		</div>
		 
	)
}

const CustomTooltip__apdex = ({ active, payload, label }) => {
	 
	if (active) { 
		var label_content = "";
		let sub_label_content = "";
		if (typeof label === 'undefined' || label === null || label === 0 || isNaN(label)==false) {
			
			label_content = (typeof payload !== 'undefined' && payload !== null && payload.length > 0) ? moment(payload[0].date).format("DD MMM YYYY HH:mm:ss") : "00:00:00";

		} else {

			if (moment(label).utc().hours()===0 && moment(label).utc().minutes()===0 ){
				// show only the days  
				label_content = moment(label).format("DD MMM YYYY") 
			}else{
				 
				label_content = moment(label).format("HH:mm A") 
				label_content += " → "; 
				label_content += moment(label).add(5-1,'minutes').format("HH:mm A");
				sub_label_content = moment(label).format("DD MMM YYYY") 
			}


		}
	  	return (
			<div className="custom-tooltip ">
				<span className="label">
					<span>{label_content}</span>
					{
						sub_label_content !=='' && <span className="sub-label">{sub_label_content}</span>
					}
				</span>
				<span className="value">{`${(typeof payload!=='undefined' && payload!==null && payload.length > 0) ? payload[0].value : 0}`}</span>
			</div>
		);
	}
  
	return null;
};
const CustomTooltip__downtime = ({ active, payload, label }) => {
	 
	if (active) { 
		var label_content = "";
		let sub_label_content = "";
		if (typeof label === 'undefined' || label === null || label === 0 || isNaN(label)==false) {
			
			label_content = (typeof payload !== 'undefined' && payload !== null && payload.length > 0) ? moment(payload[0].date).format("DD MMM YYYY HH:mm:ss") : "00:00:00";

		} else {

			if (moment(label).utc().hours()===0 && moment(label).utc().minutes()===0 ){
				// show only the days  
				label_content = moment(label).format("DD MMM YYYY") 
			}else{
				label_content = moment(label).format("HH:mm A") 
				label_content += " → "; 
				label_content += moment(label).add(59,'minutes').format("HH:mm A") ;
				sub_label_content = moment(label).format("DD MMM YYYY") 
			}
		}
	  	return (
			<div className="custom-tooltip ">
				<span className="label">
					<span>{label_content}</span>
					{
						sub_label_content !=='' && <span className="sub-label">{sub_label_content}</span>
					}
				</span>
				<span className="value">{`${(typeof payload!=='undefined' && payload!==null && payload.length > 0) ? payload[0].value : 0} times`}</span>
			</div>
		);
	}
  
	return null;
};

export default class RechartLineChart extends PureComponent {
	
	constructor(props) {
		super(props)
		 
		this.state = {
			chart: {
				width: (typeof props.width!=='undefined') ? props.width : '100%',
				height: (typeof props.height!=='undefined') ? props.height : '80%'
			},
			data: props.data,
			chartType: (typeof props.chartType !== 'undefined') ? props.chartType : 'line',
			XAxisInterval : (typeof props.XAxisInterval !=='undefined') ? props.XAxisInterval : 'hours'
		}

		// console.log("Constructor");
		

	}
	UNSAFE_componentWillMount() {
		// console.log("Component will mount");
	}
	
	UNSAFE_componentDidMount() {
		// console.log("Component did mount");
		 
	}
	  
	UNSAFE_componentWillReceiveProps(nextProps) {
		// console.log("Component will receive props", nextProps);
		//this.forceUpdate();
	}

	UNSAFE_shouldComponentUpdate(nextProps, nextState) {
		// console.log("Should Component update", nextProps, nextState)
		return true;
	}
	
	UNSAFE_componentWillUpdate(nextProps, nextState) {
		// console.log("component will update", nextProps, nextState);
		
	}

	UNSAFE_componentDidUpdate(prevProps, prevState) {
		// console.log("component did update" , prevProps , prevState);
	}

	UNSAFE_componentWillUnmount() {
		// console.log("Component will unmount");
	}

	render() {
		// console.log(this.state);
		if (this.state.chartType === 'responsetime') {
			
			return (
			<ResponsiveContainer width={"100%"} height={200} >
				<LineChart
					data={this.state.data}
					
					margin={{
						top: 5, right: 5, left: 5, bottom: 5,
					}}>
					<CartesianGrid strokeDasharray="5 5" stroke="#eee"/>
					<XAxis dataKey="date" stroke="#afafaf" tick={{fill: "#afafaf", fontSize: 10}} tickFormatter={tickItem => (this.state.XAxisInterval==='days') ? moment(tickItem).format('MMM DD') : moment(tickItem).format('HH:mm')  }  />
					<YAxis type="number" stroke="#afafaf" domain={[ 0 , 'auto']} tick={{fill: "#afafaf", fontSize: 10}} tickFormatter={tickItem => tickItem + ' ms'}/>
					<Tooltip content={<CustomTooltip__responsetime   />}  />
					{/* <Legend /> */}
					<Line type="monotone" dataKey="value" stroke="#607d8b" strokeWidth={2}  dot={false} legendType={"none"}  />
					<Line type="monotone" dataKey="contentTransfer" stroke="#28d102" strokeWidth={2}  dot={false} legendType={"none"}  />
					<Line type="monotone" dataKey="dnsLookup" stroke="#f42a04" strokeWidth={2}  dot={false} legendType={"none"}  />
					<Line type="monotone" dataKey="firstByte" stroke="#673ab7" strokeWidth={2}  dot={false} legendType={"none"}  />
					<Line type="monotone" dataKey="tcpConnection" stroke="#2196f3" strokeWidth={2}  dot={false} legendType={"none"}  />
					<Line type="monotone" dataKey="wait" stroke="#ffc107" strokeWidth={2}  dot={false} legendType={"none"}  />
				</LineChart>
			</ResponsiveContainer>
			);
		}else if (this.state.chartType === 'uptime') {
			
			return (
			<ResponsiveContainer width={"100%"} height={60} >
				<LineChart
					data={this.state.data}
					
					margin={{
						top: 5, right: 5, left: 5, bottom: 5,
					}}>
					<defs>
						<linearGradient id="colorUv_uptime" x1="0%" y1="0%" x2="0%" y2="100%">
							<stop offset="50%" stopColor="#0ace66" stopOpacity={1}/>
							<stop offset="100%" stopColor="#fe4a49" stopOpacity={0.3}/>
						</linearGradient>
					</defs>
					{/* <CartesianGrid strokeDasharray="5 5" stroke="#eee"/> */}
					{/* <XAxis name="" dataKey="date" stroke="#afafaf" tick={{fill: "#afafaf", fontSize: 10}} tickFormatter={tickItem => (this.state.XAxisInterval==='days') ? moment(tickItem).format('MMM DD') : moment(tickItem).format('HH:mm') }  /> */}
					{/* <YAxis type="number" stroke="#afafaf" domain={[ 0 , 'auto']} tick={{fill: "#afafaf", fontSize: 10}} tickFormatter={tickItem => tickItem + '%'}/> */}
					<Tooltip content={<CustomTooltip__uptime   />}  />
					{/* <Legend /> */}
					{/* <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2}  dot={false}
					legendType={"none"}  /> */}
					<Line type='monotone' dataKey='value' stroke='url(#colorUv_uptime)' strokeWidth={2} dot={false} activeDot={false} />
				</LineChart>
			</ResponsiveContainer>
			);
		}else if (this.state.chartType === 'downtime') {

			return (
			<ResponsiveContainer width={"100%"} height={160} >
				<LineChart
					data={this.state.data}
					
					margin={{
						top: 5, right: 5, left: 5, bottom: 5,
					}}>
					<defs>
						<linearGradient id="colorUv_downtime" x1="0%" y1="0%" x2="0%" y2="100%">
							<stop offset="80%" stopColor="#fe4a49" stopOpacity={1}/>
							<stop offset="100%" stopColor="#0ace66" stopOpacity={0.3}/>
						</linearGradient>
					</defs>
					<CartesianGrid strokeDasharray="5 5" stroke="#eee"/>
					<XAxis name="" dataKey="date" stroke="#afafaf" tick={{fill: "#afafaf", fontSize: 10}} tickFormatter={tickItem => (this.state.XAxisInterval==='days') ? moment(tickItem).format('MMM DD') : moment(tickItem).format('HH:mm') }  />
					<YAxis type="number" stroke="#afafaf" domain={[ 0 , 'auto']} tick={{fill: "#afafaf", fontSize: 10}} />
					<Tooltip content={<CustomTooltip__downtime   />}  />
					{/* <Legend /> */}
					<Line type="monotone" dataKey="value" stroke='url(#colorUv_downtime)'  strokeWidth={2}  dot={false} legendType={"none"}  />
				</LineChart>
			</ResponsiveContainer>
			);
		}else if (this.state.chartType === 'apdex') {
			
			return (
			<ResponsiveContainer width={"100%"} height={160} >
				<LineChart
					data={this.state.data}
					
					margin={{
						top: 5, right: 5, left: 5, bottom: 5,
					}}>
					<defs>
						<linearGradient id="colorUv_apdex" x1="0%" y1="0%" x2="0%" y2="100%">
							<stop offset="60%" stopColor="#0ace66" stopOpacity={1}/>
							<stop offset="100%" stopColor="#fe4a49" stopOpacity={0.3}/>
						</linearGradient>
					</defs>
					<CartesianGrid strokeDasharray="5 5" stroke="#eee"/>
					<XAxis name="" dataKey="date" stroke="#afafaf" tick={{fill: "#afafaf", fontSize: 10}} tickFormatter={tickItem => (this.state.XAxisInterval==='days') ? moment(tickItem).format('MMM DD') : moment(tickItem).format('HH:mm') }  />
					<YAxis type="number" stroke="#afafaf" domain={[ 0 , 'auto']} tick={{fill: "#afafaf", fontSize: 10}} />
					<Tooltip content={<CustomTooltip__apdex   />}  />
					{/* <Legend /> */}
					{/* <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2}  dot={false} legendType={"none"}  /> */}
					<Line type='monotone' dataKey='value' stroke='url(#colorUv_apdex)' strokeWidth={2} dot={false} activeDot={false} />
				</LineChart>
			</ResponsiveContainer>
			);
		}
		 
	}
}

export class RechartAreaChart extends PureComponent {
	constructor(props) {
		super(props)
		 
		this.state = {
			chart: {
				width: (typeof props.width!=='undefined') ? props.width : '100%',
				height: (typeof props.height!=='undefined') ? props.height : '80%'
			},
			data: props.data,
			chartType: (typeof props.chartType !== 'undefined') ? props.chartType : 'line',
			XAxisInterval : (typeof props.XAxisInterval !=='undefined') ? props.XAxisInterval : 'hours',
			chartColor : (typeof props.chartColor !=='undefined') ? props.chartColor :'#333333',
			hashId : (typeof props.hashId !=='undefined') ? props.hashId :null,
		}

	 
		

	}

	UNSAFE_componentWillMount() {
		// console.log("Component will mount");
	}
	
	UNSAFE_componentDidMount() {
		// console.log("Component did mount");
		 
	}
	  
	UNSAFE_componentWillReceiveProps(nextProps) {
		// console.log("Component will receive props", nextProps);
		this.setState({ data : nextProps})
		// this.forceUpdate();
	}

	UNSAFE_shouldComponentUpdate(nextProps, nextState) {
		// console.log("Should Component update", nextProps, nextState)
		return true;
	}
	
	UNSAFE_componentWillUpdate(nextProps, nextState) {
		// console.log("component will update", nextProps, nextState);
		
	}

	UNSAFE_componentDidUpdate(prevProps, prevState) {
		// console.log("component did update" , prevProps , prevState);
	}

	UNSAFE_componentWillUnmount() {
		// console.log("Component will unmount");
	}

	render() {
		 
		if (this.state.chartType === 'uptime') {

			 

			
			return (
				<ResponsiveContainer width={"100%"} height={80} >
					<AreaChart
						data={this.props.data}
						margin={{
							top: 5, right: 0, left: 0, bottom: -13,
							}}
						syncId={this.state.hashId}>
						
						<defs>
							<linearGradient id={`colorUv_uptime__${this.state.hashId}`} x1="0" y1="0" x2="0" y2="1">
								<stop offset="10%" stopColor={this.state.chartColor} stopOpacity={0.3}/>
								<stop offset="100%" stopColor={this.state.chartColor} stopOpacity={0}/>
							</linearGradient>
							<linearGradient id={`colorUv_outage__${this.state.hashId}`} x1="0" y1="0" x2="0" y2="1">
								<stop offset="10%" stopColor={`#ff0000`} stopOpacity={0.3}/>
								<stop offset="100%" stopColor={`#ff0000`} stopOpacity={0}/>
							</linearGradient>
						</defs>
						<XAxis 
							dataKey="date" 
							stroke="#efefef" 
							tick={{fill: "#999", fontSize: 11}} 
							tickFormatter={tickItem =>{ return (this.state.XAxisInterval==='days') ? moment(tickItem).format('MMM DD') : moment(tickItem).format('HH:mm')} }  
							
							/>
						<YAxis 
							yAxisId="left"
							type="number" 
							stroke="#efefef" 
							domain={[ 0 , 'auto']} 
							tick={{fill: "#999", fontSize: 11}} 
							width={50}
							tickFormatter={tickItem =>{ return tickItem + ' %'} }  
							/>
						{
							this.props.data.outage!==null && 
							<YAxis 
								hide={true}
								yAxisId="right"
								orientation="right"
								type="number" 
								stroke="#efefef" 
								domain={[ 0 , 'auto']} 
								tick={{fill: "#999", fontSize: 11}} 
								width={50}
								tickFormatter={tickItem =>{ return tickItem } }  
							/>
						}
						<Tooltip content={<CustomTooltip__uptime   />}  />
						{
							this.props.data.outage!==null && 
							<Area  
								yAxisId="right"
								type="monotone" 
								dataKey="outage" 
								stroke={`#ff0000`} 
								strokeWidth={2}  
								fillOpacity={1} 
								fill={`url(#colorUv_outage__${this.state.hashId})`} 
								dot={false} 
								legendType={"none"}
								isAnimationActive={false}  />
						}
						<Area  
							yAxisId="left"
							type="monotone" 
							dataKey="value" 
							stroke={this.state.chartColor} 
							strokeWidth={2}  
							fillOpacity={1} 
							fill={`url(#colorUv_uptime__${this.state.hashId})`} 
							dot={false} 
							legendType={"none"}
							isAnimationActive={false}  />
						
					</AreaChart>
				</ResponsiveContainer>
				);
			
		}else if (this.state.chartType === 'responsetime') {

			
			return (
				<ResponsiveContainer width={"100%"} height={80} >
					<AreaChart
						data={this.props.data}
						margin={{
							top: 5, right: 0, left: 0, bottom: -13,
							}}
						syncId={this.state.hashId}>
						
						<defs>
							<linearGradient id={`colorUv_responsetime_${this.state.hashId}`} x1="0" y1="0" x2="0" y2="1">
								<stop offset="10%" stopColor={this.state.chartColor} stopOpacity={0.3}/>
								<stop offset="100%" stopColor={this.state.chartColor} stopOpacity={0}/>
							</linearGradient>
						</defs>
						<XAxis 
							dataKey="date" 
							stroke="#efefef" 
							tick={{fill: "#999", fontSize: 11}} 
							tickFormatter={tickItem =>{ return (this.state.XAxisInterval==='days') ? moment(tickItem).format('MMM DD') : moment(tickItem).format('HH:mm')} }  
							
							/>
						<YAxis 
							type="number" 
							stroke="#efefef" 
							domain={[ 0 , 'auto']} 
							tick={{fill: "#999", fontSize: 11}} 
							width={50}
							tickFormatter={tickItem =>{ return (tickItem >= 1000 ) ? (tickItem/1000).toFixed(0)+' s' : tickItem + ' ms'} }  />
						<Tooltip content={<CustomTooltip__responsetime   />}  />
						<Area  type="monotone" 
							dataKey="value" 
							stroke={this.state.chartColor} 
							strokeWidth={2}  
							fillOpacity={1} 
							fill={`url(#colorUv_responsetime_${this.state.hashId})`} 
							dot={false} 
							legendType={"none"} 
							isAnimationActive={false} />
					</AreaChart>
				</ResponsiveContainer>
				);
			
		}
		 
	}
}

export class RechartBarChart extends PureComponent {
	constructor(props) {
		super(props)
		 
		this.state = {
			chart: {
				width: (typeof props.width!=='undefined') ? props.width : '100%',
				height: (typeof props.height!=='undefined') ? props.height : '80%'
			},
			data: props.data,
			chartType: (typeof props.chartType !== 'undefined') ? props.chartType : 'apdex',
			barColor: (typeof props.barColor !== 'undefined') ? props.barColor : "#F44336",
			chartColor : (typeof props.chartColor !=='undefined') ? props.chartColor :'#333333',
			XAxisInterval : (typeof props.XAxisInterval !=='undefined') ? props.XAxisInterval : 'hours',
			hashId : (typeof props.hashId !=='undefined') ? props.hashId :null,
		}

		 

	}


	UNSAFE_componentWillMount() {
		// console.log("Component will mount");
	}
	
	UNSAFE_componentDidMount() {
		// console.log("Component did mount");
		 
	}
	  
	UNSAFE_componentWillReceiveProps(nextProps) {
		// console.log("Component will receive props", nextProps);
		this.setState({ data : nextProps})
		// this.forceUpdate();
	}

	UNSAFE_shouldComponentUpdate(nextProps, nextState) {
		// console.log("Should Component update", nextProps, nextState)
		return true;
	}
	
	UNSAFE_componentWillUpdate(nextProps, nextState) {
		// console.log("component will update", nextProps, nextState);
		
	}

	UNSAFE_componentDidUpdate(prevProps, prevState) {
		// console.log("component did update" , prevProps , prevState);
	}

	UNSAFE_componentWillUnmount() {
		// console.log("Component will unmount");
	}

	render() {
		 
		if( this.state.chartType === 'uptime'){
			// console.log(this.props.avg_uptime);
			 
			return (
				<ResponsiveContainer width={"100%"} height={65} >
					<BarChart
						data={this.props.data}
						stackOffset="sign"
						margin={{
							top: 5, right: 0, left: 0, bottom:0,
						}}
						// barSize={5}
						// width={5}
						height={40}
						// barGap={10}
						>
						{/* <CartesianGrid strokeDasharray="5 5" stroke="#eee"/> */}
						<XAxis hide={true} dataKey="date" stroke="#afafaf" tick={{fill: "#afafaf", fontSize: 10}} tickFormatter={tickItem => (this.state.XAxisInterval==='days') ? moment(tickItem).format('MMM DD') : moment(tickItem).format('HH:mm') }  />
						{/* <YAxis type="number" stroke="#afafaf" domain={[ 0 , 'auto']} tick={{fill: "#afafaf", fontSize: 10}} domain={[0, 1]} /> */}
						<Tooltip 
							content={<CustomTooltip__uptime_bars   />} 
							cursor={false}
							// offset={0}
							// position={{ x: 0, y: 0 }} // this was my preferred static position
							// coordinate={{ x: 100, y: 100 }} // this was my preferred static position
							// viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
							// position={{ y: 30 }}
							/>
						
						{/* <Bar dataKey="value" fill="#8884d8"   stackId="stack" />
						<Bar dataKey="outage" fill="#ff0000"  stackId="stack" /> */}
						<Legend 
							content={<CustomLegend__uptime_bars daysCount={this.props.data.length} uptimeValue={this.props.avgUptime} />} 
							verticalAlign="bottom"
							/>
						<Bar dataKey="value"  background={{ fill: '#ddd' }} /* barSize={5} */>
							{this.props.data.map((entry, index) => {
								 
								return (
								<Cell cursor="pointer" fill={ 
										entry.is_outage === true  
										? (
											entry.outage <= 8 
											? '#ffb500'
											: entry.outage > 8 && entry.outage <=16
												? '#ff9200'
												: '#e90000'
										) 
										: entry.uptime > 0 
											? "#00d100"//this.state.chartColor
											: '#eee' } key={`cell-${index}`} />
								)
							}
							)}
						</Bar>
					</BarChart>
				</ResponsiveContainer>
				);
		}else if (this.state.chartType === 'apdex') {
			
			return (
			<ResponsiveContainer width={"100%"} height={160} >
				<BarChart
					data={this.state.data}
					
					margin={{
						top: 5, right: 5, left: 5, bottom: 5,
					}}>
					<CartesianGrid strokeDasharray="5 5" stroke="#eee"/>
					<XAxis dataKey="date" stroke="#afafaf" tick={{fill: "#afafaf", fontSize: 10}} tickFormatter={tickItem => (this.state.XAxisInterval==='days') ? moment(tickItem).format('MMM DD') : moment(tickItem).format('HH:mm') }  />
					<YAxis type="number" stroke="#afafaf" domain={[ 0 , 'auto']} tick={{fill: "#afafaf", fontSize: 10}} domain={[0, 1]} />
					<Tooltip content={<CustomTooltip__apdex   />}  />
					{/* <Legend /> */}
					{/* <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2}  dot={false}
					legendType={"none"}  /> */}
					<Bar dataKey="value" fill="#8884d8" background={{ fill: '#eee' }} />
				</BarChart>
			</ResponsiveContainer>
			);
		}else if (this.state.chartType === 'downtime') {
			
			return (
			<ResponsiveContainer width={"100%"} height={160} >
				<BarChart
					data={this.state.data}
					
					margin={{
						top: 5, right: 5, left: 5, bottom: 5,
					}}>
					<CartesianGrid strokeDasharray="5 5" stroke="#eee"/>
					<XAxis dataKey="date" stroke="#afafaf" tick={{fill: "#afafaf", fontSize: 10}} tickFormatter={tickItem => (this.state.XAxisInterval==='days') ? moment(tickItem).format('MMM DD') : moment(tickItem).format('HH:mm') }  />
					<YAxis type="number" stroke="#afafaf" domain={[ 0 , 'auto']} tick={{fill: "#afafaf", fontSize: 10}} domain={[0, 1]} />
					<Tooltip content={<CustomTooltip__downtime   />}  />
					{/* <Legend /> */}
					{/* <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2}  dot={false}
					legendType={"none"}  /> */}
					<Bar dataKey="value" fill="#F44336" background={{ fill: '#eee' }} />
				</BarChart>
			</ResponsiveContainer>
			);
		}else if (this.state.chartType === 'simple') {
			
			return (
			<ResponsiveContainer width={"100%"} height={this.state.chart.height} >
				<BarChart
					data={this.state.data}
					
					margin={{ 
						top: 5, right: 5, left: 5, bottom: 5,
					}}>
					<CartesianGrid strokeDasharray="5 5" stroke="#eee"/>
					<XAxis hide={true} dataKey="date" stroke="#afafaf" tick={{fill: "#afafaf", fontSize: 10}} tickFormatter={tickItem => (this.state.XAxisInterval==='days') ? moment(tickItem).format('MMM DD') : moment(tickItem).format('HH:mm') }  />
					<YAxis hide={true} type="number" stroke="#afafaf" domain={[ 0 , 'auto']} tick={{fill: "#afafaf", fontSize: 10}} domain={[0, 1]} />
					{/* <Tooltip content={<CustomTooltip__downtime   />}  /> */}
					{/* <Legend /> */}
					{/* <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2}  dot={false}
					legendType={"none"}  /> */}
					<Bar dataKey="value" fill={this.state.barColor} background={{ fill: '#eee' }} />
				</BarChart>
			</ResponsiveContainer>
			);
		}
		 
	}
}

// export class CustombarChart extends PureComponent {
// 	constructor(props){
// 		super(props);

// 		this.state = {
// 			chart: {
// 				width: (typeof props.width!=='undefined') ? props.width : '100%',
// 				height: (typeof props.height!=='undefined') ? props.height : '80%'
// 			},
// 			data: props.data,
// 			chartType: (typeof props.chartType !== 'undefined') ? props.chartType : 'line',
// 			XAxisInterval : (typeof props.XAxisInterval !=='undefined') ? props.XAxisInterval : 'hours',
// 			chartColor : (typeof props.chartColor !=='undefined') ? props.chartColor :'#333333',
// 			hashId : (typeof props.hashId !=='undefined') ? props.hashId :null,

// 		}
// 	}


// 	render(){

// 		return(
// 			<div className="rechart-custom-bars">

// 				<div className="legend"></div> 	
// 			</div>
// 		)
// 	}
// }
