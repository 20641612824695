import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
  // <BrowserRouter>
    <App />
  // </BrowserRouter>, 
  ,document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

// THIS IS JUST A COMMENT TO TEST THE NEW RUNNER FROM GITHUB ACTIONS
