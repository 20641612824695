import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import invert from 'invert-color'

import SubscribersDropDown from '../SubscribersDropDown/SubscribersDropDown';

export default class Navbar extends Component {
	constructor(props){
		super(props);
		this.state = {
			logo_url : (this.props.logo_url !=='undefined' && this.props.logo_url !==null && this.props.logo_url !=='') ? this.props.logo_url : null,
			homepageLink : (this.props.homepageLink !=='undefined' && this.props.homepageLink !==null && this.props.homepageLink !=='') ? this.props.homepageLink : null,
			name : (this.props.name !=='undefined' && this.props.name !==null && this.props.name !=='') ? this.props.name : null,
			backgroundColor : (this.props.backgroundColor !=='undefined' && this.props.backgroundColor !==null && this.props.backgroundColor !=='') ? this.props.backgroundColor : null,
		}
	}
 
	render() {

		let textColorMode = 'lightTextColor';
		if(this.props.backgroundColor !==null )
			textColorMode = invert(this.props.backgroundColor, { black: 'dark', white: 'light' })
		

		return (
			<div className="navbar-wrapper">
				<div className="navbar-content">
					<div className="container">
						{
							this.props.logo_url !==null  &&
							<div className="statuspage-logo">
								<Link to="/"  >
									<img src={this.props.logo_url} alt={`${this.props.name} Status`} height="40"/>
								</Link>
							</div>
						}
						<div className="statuspage-menu">
							<ul>
								{ 
									this.props.homepageLink !==null && 
									<li>
										<a href={this.props.homepageLink} target='_blanc' className={`${textColorMode}`} >
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-external-link"  ><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
											<span>Go to {this.props.name !==null ? this.props.name : 'Home page'}</span>
										</a>
									</li>
								}
								{
									this.props.allowSubscriptions === true && 
									<li>
										<SubscribersDropDown 
											allowedChannelsSubs={this.props.allowedChannelsSubs}
											// statusPageDomain={this.props.statusPageDomain}
											// statusPageType={this.props.statusPageType}
											/>
									</li>
								}
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
