import axios from 'axios';
import React, { useState , useEffect , useRef , useContext } from 'react'
import { Redirect  } from "react-router-dom";
import LoadingSpinner from '../../Layout/LoadingSpinner/LoadingSpinner'
import Helper from './../../../utils/helper'

function UnsubscribeProcess(props) {

	const [unsubscribe_token, setUnsubscribe_token] = useState(null)
	const [unsubscribe_confirmed, setUnsubscribe_confirmed] = useState(false)
	const [loading, setLoading] = useState(0)

	useEffect(() => {
		const token = props.match.params.token;
		// console.log(token);
		setUnsubscribe_token(token)
		validateEmailSubscription(token);
	} , [unsubscribe_token]);


	/**
	 * 
	 */
	const validateEmailSubscription = async () => {
		setLoading(1);

		const headers = {
			'Content-Type': 'application/json',
		}

		let customConfig = {
			token : unsubscribe_token
		}

		if(unsubscribe_token!==null){

			await axios
				.put(`${process.env.REACT_APP_API_URL}public/subscriptions/unsubscribe/${unsubscribe_token}`, 
				customConfig
				,{
					headers: headers,
				})
				.then(res => res.data ) 
				.then(data => { 
	
					setUnsubscribe_confirmed(true)
					setLoading(2); // stop loading ... 
					 
							 
				})
				.catch(err => {
	
					setUnsubscribe_confirmed(false) 
					setLoading(2)
					 
				 
				});
		}

	}

	if(loading === 2){
		return <Redirect to={`/?unsubscribe_confirmed=${unsubscribe_confirmed}`} />	
	}

	return (
		<div className="" >
			{
				loading && 
				<div className="loading-wrapper"><LoadingSpinner /></div>
			}
		</div>
	)
}

export default UnsubscribeProcess;
