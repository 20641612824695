import React , { Component } from "react";
import axios from 'axios';
import {Link} from 'react-router-dom' 
import moment from 'moment'
import Countdown from 'react-countdown';
import LoadingSpinner from '../../Layout/LoadingSpinner/LoadingSpinner'
// Invoke the global state
import AppContext from './../../../context/app-context'
// import css 
import './Header-notifications.css'
// load icons 
import { ReactComponent as IncidentIcon } from './../../../assets/icons/flash.svg'
import { ReactComponent as MaintenanceIcon } from './../../../assets/icons/cone.svg'

export default class LastActiveIncidents extends Component{

	// permission to use the global state 
	static contextType = AppContext;
	signal = axios.CancelToken.source();
	
	constructor(props){
		super(props);

		this.state = {
			activeIncidents : [],
			isLoading : false ,
			isCompletedLoading : false
		}

		this.getActiveIncidents = this.getActiveIncidents.bind(this)
	}

	/**
	 * 
	 */
	getActiveIncidents(){

		this.setState({
			isLoading : true 
		})

		 

		axios
			.get(`${process.env.REACT_APP_API_URL}public/statuspages/${this.props.statusPageDomain}/active-incidents`, {
				params: {
					//w_data: true,
					type_incident : 
						(this.props.showOpenIncidents.realtime === true && this.props.showOpenIncidents.scheduled === true )
						? 'all'
						: this.props.showOpenIncidents.realtime === true 
							? 'only_realtime'
							: 'only_scheduled'
				},
				cancelToken: this.signal.token
			})
			.then(res => res.data)
			.then(data => {

				let stateItems = {}

             
				// if(typeof data.events!=='undefined' && data.events!==null && data.events.length > 0) {
							
				// 	data.events.sort((a,b) => moment(b.created_at).unix() - moment(a.created_at).unix())

				// }

				stateItems.activeIncidents = data

				stateItems.isLoading = false;
				stateItems.isCompletedLoading = true;
				this.setState(stateItems);
				 
			})
			.catch(error => {

				let stateItems = {}

				if (axios.isCancel(error)) {
					//console.log('Error: ', err.message); // => prints: Api is being canceled
				} else {
					stateItems.isLoading = false
					stateItems.isCompletedLoading = true;
				}


				this.setState(stateItems);
			})
	}

	renderer_maintenanceCompletedIn({ days, hours, minutes, seconds, completed }){
	  if (completed) {
		// Render a completed state
		return ''
	  } else {
		// Render a countdown
		return <span>
			{ days > 0 && `${days} ${days === 1 ? 'day' : 'days'} , `}
			{ hours > 0 && `${hours} ${hours === 1 ? 'hour' : 'hours'} , `}
			{ minutes > 0 && `${minutes} min and `}
			{ seconds > 0 && `${seconds} sec` }
		</span>
		// return <span>
		// 	{
		// 		`${hours > 0 ? `${hours} hours` }`
		// 	}
		// 	{hours} hours, {minutes} min and {seconds} sec</span>;
	  }
	};


	componentDidMount(){
		this.getActiveIncidents()
	}

	render(){

		if(this.state.isCompletedLoading && this.state.activeIncidents.length <=0 ){
			return null;
		}

		return(
			<div className="global-incidents-header active-incidents">
				<div className="container">
				<div className="white-card">
					<h1>Open incidents</h1>
					<div className="active-incidents-content">
						{
							this.state.isLoading 
							? <div className="loading-wrapper"><LoadingSpinner /></div>
							: (
								this.state.activeIncidents.map( (incident , index) => {

									const time_left_to_ends = moment(incident.scheduled_until).diff(moment()).valueOf();
									const time_left_to_start = moment(incident.scheduled_for).diff(moment()).valueOf();

									let progressbar_value = 0;
									let progressbar_value_status = 'danger';

									if(incident.is_scheduled === true ){ // Scheduled Maintenance 
										if(moment() > moment(incident.scheduled_for) && moment() < moment(incident.scheduled_until)){
											const fulltime = moment(incident.scheduled_until).diff(moment(incident.scheduled_for)).valueOf();
											progressbar_value = (( fulltime - time_left_to_ends) / fulltime) * 100;
											
										}
									}else{ // Simple incident
										switch(incident.events[incident.events.length -1].type){
											case 'INVESTIGATING': progressbar_value = 25; break;
											case 'IDENTIFIED': progressbar_value = 50; break;
											case 'MONITORING': progressbar_value = 75 ;break;
											case 'RESOLVED': progressbar_value = 100; break;
										}	
									}

									if(progressbar_value <= 30) progressbar_value_status = 'danger';
									else if(progressbar_value > 30 && progressbar_value <= 60 ) progressbar_value_status = 'warning';
									else if(progressbar_value > 60 && progressbar_value <= 80 ) progressbar_value_status = 'pre-success';
									else if(progressbar_value > 80  ) progressbar_value_status = 'success';

									  
									return (
										<div key={index} className={`act-inc_wrapper`} >
											<Link to={`/incidents/${incident.id}`}>

												<div className="act-inc_row-main-info">
													<div className="act-inc_title line-clamp-1">
														<span className="act-inc__type" >{
															incident.is_scheduled === true 
															? <MaintenanceIcon /> 
															: <IncidentIcon />
														}</span>
														<span>{incident.name}</span>
													</div> 
													<div className="act-inc_progressbar">
														<div className={`act-inc_pbar--content ${progressbar_value_status}`} style={{width : progressbar_value+'%'}}></div>
													</div>
												</div>
												<div className="act-inc_row-sec-info">
													<div className="act-inc_event-date">{moment(incident.events[0].created_at).format('MMM DD, hh:mm A')}</div>
													{
														incident.is_scheduled === true && 
														time_left_to_ends > 0 && 
														moment() >= moment(incident.scheduled_for) && 
														moment() <= moment(incident.scheduled_until) && 
														<>
														<span className="separator">.</span>
														<div className="act-inc_event-counterDown lefttime">
															<span>Completed in </span>
															<Countdown date={Date.now() + time_left_to_ends} renderer={this.renderer_maintenanceCompletedIn} />
														</div>
														</>
													}
													{
														incident.is_scheduled === true && 
														time_left_to_start > 0 && 
														moment() <= moment(incident.scheduled_for)  && 
														<>
														<span className="separator">.</span>
														<div className="act-inc_event-counterDown timestart">
															<span>Start in </span>
															<Countdown date={Date.now() + time_left_to_start} renderer={this.renderer_maintenanceCompletedIn} />
														</div>
														</>
													}
												</div>

												{/* <div className={`act-inc_event-type ${incident.events[0].type.toLowerCase()}`}>{incident.events[incident.events.length -1].type}</div>
												<div className="act-inc_event-msg">{incident.events[0].message}</div> */}
											</Link>
										</div>
									)
								})
							)
						}
					</div>
				</div>
				</div>
			</div>
		)
	}
}