const moment = require('moment')
const locations = [
	// {
	// 	id: "california",
	// 	name: "california",
	// 	location : "California",
	// 	countryCode : "US",
	// 	countryName : "United State",
	// 	chartColor : "#2196f3"
	// },
	// {
	// 	id: "singapore",
	// 	name: "singapore",
	// 	location : "Singapore",
	// 	countryCode : "SG",
	// 	countryName : "Singapore",
	// 	chartColor : "#673ab7"
	// },
	// {
	// 	id: "frankfurt",
	// 	name: "frankfurt",
	// 	location : "Frankfurt",
	// 	countryCode : "DE",
	// 	countryName : "Germany",
	// 	chartColor : "#ffc107"
	// },
	// {
	// 	id: "sydney",
	// 	name: "sydney",
	// 	location : "Sydney",
	// 	countryCode : "AU",
	// 	countryName : "Australia",
	// 	chartColor : "#4caf50"
	// },
	{
		id: "toronto",
		name: "toronto",
		location : "Toronto",
		countryCode : "CA",
		countryName : "Canada",
		chartColor : "#d52c1e",
		isActive: false
	},

	// ! NEW REGIONS FROM AWS 
	{
		id: "virginia",
		name: "virginia",
		location: "Virginia",
		aws_region_id: "us-east-1",
		countryCode: "US",
		countryName: "United State",
		chartColor:  "#2196f3",
		isActive: true
	},
	{
		id: "ohio",
		name: "ohio",
		location: "Ohio",
		aws_region_id: "us-east-2",
		countryCode: "US",
		countryName: "United State",
		chartColor: "#0057e7",
		isActive: true
	},
	{
		id: "california",
		name: "california",
		location: "California",
		aws_region_id: "us-west-1",
		countryCode: "US",
		countryName: "United State",
		chartColor: "#a8e6cf",
		isActive: true
	},
	{
		id: "oregon",
		name: "oregon",
		location: "Oregon",
		aws_region_id: "us-west-2",
		countryCode: "US",
		countryName: "United State",
		chartColor: "#ff77ff",
		isActive: true
	},
	{
		id: "ireland",
		name: "ireland",
		location: "Ireland",
		aws_region_id: "eu-west-1",
		countryCode: "IE",
		countryName: "Ireland",
		chartColor: "#22ffaa",
		isActive: true
	},
	{
		id: "london",
		name: "london",
		location: "London",
		aws_region_id: "eu-west-2",
		countryCode: "GB",
		countryName: "United Kingdom",
		chartColor: "#996666",
		isActive: true
	},
	{
		id: "paris",
		name: "paris",
		location: "Paris",
		aws_region_id: "eu-west-3",
		countryCode: "FR",
		countryName: "France",
		chartColor: "#c8241b",
		isActive: true
	},
	{
		id: "frankfurt",
		name: "frankfurt",
		location: "Frankfurt",
		aws_region_id: "eu-central-1",
		countryCode: "DE",
		countryName: "Germany",
		chartColor: "#ffc107",
		isActive: true
	},
	{
		id: "singapore",
		name: "singapore",
		location: "Singapore",
		aws_region_id: "ap-southeast-1",
		countryCode: "SG",
		countryName: "Singapore",
		chartColor: "#673ab7",
		isActive: true
	},
	{
		id: "sydney",
		name: "sydney",
		location: "Sydney",
		aws_region_id: "ap-southeast-2",
		countryCode: "AU",
		countryName: "Australia",
		chartColor: "#fbb40c",
		isActive: true
	},
	{
		id: "tokyo",
		name: "tokyo",
		location: "Tokyo",
		aws_region_id: "ap-northeast-1",
		countryCode: "JP",
		countryName: "Japan",
		chartColor: "#81607f",
		isActive: true
	},
	{
		id: "seoul",
		name: "seoul",
		location: "Seoul",
		aws_region_id: "ap-northeast-2",
		countryCode: "KR",
		countryName: "South Korea",
		chartColor: "#097f8c",
		isActive: true
	},
	{
		id: "mumbai",
		name: "mumbai",
		location: "Mumbai",
		aws_region_id: "ap-south-1",
		countryCode: "IN",
		countryName: "India",
		chartColor: "#ff9932",
		isActive: true
	},
	{
		id: "montreal",
		name: "montreal",
		location: "Montreal",
		aws_region_id: "ca-central-1",
		countryCode: "CA",
		countryName: "Canada",
		chartColor: "#d52c1e",
		isActive: true
	},
	{
		id: "stockholm",
		name: "stockholm",
		location: "Stockholm",
		aws_region_id: "eu-north-1",
		countryCode: "SE",
		countryName: "Sweden",
		chartColor: "#ffdd00",
		isActive: true
	},
	{
		id: "osaka",
		name: "osaka",
		location: "Osaka",
		aws_region_id: "ap-northeast-3",
		countryCode: "JP",
		countryName: "Japan",
		chartColor: "#00505c",
		isActive: true
	},
	{
		id: "saopaulo",
		name: "saopaulo",
		location: "São Paulo",
		aws_region_id: "me-south-1",
		countryCode: "BR",
		countryName: "Brazil",
		chartColor: "#00225c",
		isActive: true
	},

	// ? Additional AWS REGIONS 
	// {
	// 	id: "bahrain",
	// 	name: "bahrain",
	// 	location: "Bahrain",
	// 	aws_region_id: "sa-east-1",
	// 	countryCode: "BH",
	// 	countryName: "Bahrain",
	// 	chartColor: "#fb8500",
	// 	isActive: true
	// },
	// {
	// 	id: "capetown",
	// 	name: "capetown",
	// 	location: "Cape Town",
	// 	aws_region_id: "af-south-1",
	// 	countryCode: "ZA",
	// 	countryName: "South Africa",
	// 	chartColor: "#ffb703",
	// 	isActive: true
	// },
	// {
	// 	id: "hongkong",
	// 	name: "hongkong",
	// 	location: "Hong Kong",
	// 	aws_region_id: "ap-east-1",
	// 	countryCode: "HK",
	// 	countryName: "Hong Kong",
	// 	chartColor: "#78CAD2",
	// 	isActive: true
	// },
	// {
	// 	id: "milan",
	// 	name: "milan",
	// 	location: "Milan",
	// 	aws_region_id: "eu-south-1",
	// 	countryCode: "IT",
	// 	countryName: "Italy",
	// 	chartColor: "#E2B4BD",
	// 	isActive: true
	// },


	// {
	//     id: "us-east-2",
	//     name: "ohio",
	//     location : "Ohio",
	//     countryCode : "US",
	//     countryName : "United State",
	//     chartColor : "#28d102"
	// },
	// {
	//     id: "us-west-1",
	//     name: "california",
	//     location : "California",
	//     countryCode : "US",
	//     countryName : "United State",
	//     chartColor : "#f42a04"
	// },
	// {
	// 	id: "ap-northeast-2",
	// 	name: "seoul",
	// 	location : "Seoul",
	// 	countryCode : "KR",
	// 	countryName : "South-korea",
	// 	chartColor : "#673ab7"
	// },
	// {
	// 	id: "ap-southeast-2",
	// 	name: "sydney",
	// 	location : "Sydney",
	// 	countryCode : "AU",
	// 	countryName : "Australia",
	// 	chartColor : "#28d101"
	// },
	// {
	// 	id: "eu-west-3",
	// 	name: "paris",
	// 	location : "Paris",
	// 	countryCode : "FR",
	// 	countryName : "France",
	// 	chartColor : "#FF9800"
	// },
	
	// {
	// 	id: "eu-west-2",
	// 	name: "london",
	// 	location : "London",
	// 	countryCode : "GB",
	// 	countryName : "United kingdom",
	// 	chartColor : "#03a9f4"
	// },
	
	// {
	// 	id: "sa-east-1",
	// 	name: "saopaulo",
	// 	location : "São Paulo",
	// 	countryCode : "BR",
	// 	countryName : "Brazil",
	// 	chartColor : "#229e45"
	// },
	
	];
const opsgenieRegions = [
	{
		id : 'us' , name : 'US'
	},
	{
		id : 'eu' , name : 'EU'
	},
]

const incidentEvents = [
	{
		id : 'INVESTIGATING' , name : 'Investigating' , status : 'danger' ,
	},
	{
		id : 'IDENTIFIED' , name : 'Identified' , status : 'warning' ,
	},
	{
		id : 'MONITORING' , name : 'Monitoring' , status : 'warning' ,
	},
	{
		id : 'RESOLVED' , name : 'Resolved' , status : 'success' ,
	},
];

const maintenanceEvents = [
	{
		id : 'SCHEDULED' , name : 'Scheduled' , status : 'danger' ,
	},
	{
		id : 'INPROGRESS' , name : 'In Progress' , status : 'warning' ,
	},
	{
		id : 'VERIFING' , name : 'Verifing' , status : 'warning' ,
	},
	{
		id : 'COMPLETED' , name : 'Completed' , status : 'success' ,
	},
]

const statusPageAlertChannels = [
	{
		id : 'email',
		name: 'Email',
		description: 'Get alerts when a check fails and recovers in your inbox.',
		link: '/settings/alert/channels/new/email',
		icon : 'email',
		bigIcon : true,
		isNew : false
	},
	{
		id : 'slack',
		name: 'Slack',
		description: 'Get detailed failure and recovery reports in your Slack channel as they happen.',
		link: '/settings/alert/channels/new/slack',
		icon : 'slack',
		bigIcon : true,
		isNew : false
	},
	{
		id : 'sms',
		name: 'SMS',
		description: 'Get a text message on your phone on each failure and recovery.',
		link: '/settings/alert/channels/new/sms',
		icon : 'sms',
		bigIcon : true,
		isNew : false
	},
	{
		id : 'webhook',
		name: 'Webhook',
		description: 'Get a custom formatted failure / recovery message on any endpoint you want.',
		link: '/settings/alert/channels/new/webhook',
		icon : 'webhooks',
		bigIcon : true,
		isNew : false
	},
	// {
	// 	id : 'telegram',
	// 	name: 'Telegram',
	// 	description: 'Get a custom formatted failure / recovery message on any telegram group/profile you want.',
	// 	link: '/settings/alert/channels/new/telegram',
	// 	icon : 'telegram',
	// 	bigIcon : true,
	// 	isNew : moment() < moment('2021-08-24').add(30 , 'days')
	// },
	{
		id : 'discord',
		name: 'Discord',
		description: 'Get detailed failure and recovery alerts in any Discord channel.',
		link: '/settings/alert/channels/new/discord',
		icon : 'discord',
		bigIcon : true,
		isNew : moment() < moment('2021-08-27').add(30 , 'days')
	} ,
	{
		id : 'rss',
		name: 'RSS/Atom',
		description: 'Get detailed failure and recovery alerts in on Rss Or Atom format.',
		link: '/settings/alert/channels/new/rss',
		icon : 'rss',
		bigIcon : true,
		isNew : moment() < moment('2021-08-27').add(30 , 'days')
	} 
]

const phonePrefixe = {
	"af": { name : "Afghanistan (+93)" , num : 93 } ,
	"al": { name : "Albania (+355)" , num : 355 } ,
	"dz": { name : "Algeria (+213)" , num : 213 } ,
	"as": { name : "American Samoa (+1)" , num : 1 } ,
	"ad": { name : "Andorra (+376)" , num : 376 } ,
	"ao": { name : "Angola (+244)" , num : 244 } ,
	"ai": { name : "Anguilla (+1)" , num : 1 } ,
	"ag": { name : "Antigua and Barbuda (+1)" , num : 1 } ,
	"ar": { name : "Argentina (+54)" , num : 54 } ,
	"am": { name : "Armenia (+374)" , num : 374 } ,
	"aw": { name : "Aruba (+297)" , num : 297 } ,
	"au": { name : "Australia/Cocos/Christmas Island (+61)" , num : 61 } ,
	"at": { name : "Austria (+43)" , num : 43 } ,
	"az": { name : "Azerbaijan (+994)" , num : 994 } ,
	"bs": { name : "Bahamas (+1)" , num : 1 } ,
	"bh": { name : "Bahrain (+973)" , num : 973 } ,
	"bd": { name : "Bangladesh (+880)" , num : 880 } ,
	"bb": { name : "Barbados (+1)" , num : 1 } ,
	"by": { name : "Belarus (+375)" , num : 375 } ,
	"be": { name : "Belgium (+32)" , num : 32 } ,
	"bz": { name : "Belize (+501)" , num : 501 } ,
	"bj": { name : "Benin (+229)" , num : 229 } ,
	"bm": { name : "Bermuda (+1)" , num : 1 } ,
	"bo": { name : "Bolivia (+591)" , num : 591 } ,
	"ba": { name : "Bosnia and Herzegovina (+387)" , num : 387 } ,
	"bw": { name : "Botswana (+267)" , num : 267 } ,
	"br": { name : "Brazil (+55)" , num : 55 } ,
	"bn": { name : "Brunei (+673)" , num : 673 } ,
	"bg": { name : "Bulgaria (+359)" , num : 359 } ,
	"bf": { name : "Burkina Faso (+226)" , num : 226 } ,
	"bi": { name : "Burundi (+257)" , num : 257 } ,
	"kh": { name : "Cambodia (+855)" , num : 855 } ,
	"cm": { name : "Cameroon (+237)" , num : 237 } ,
	"ca": { name : "Canada (+1)" , num : 1 } ,
	"cv": { name : "Cape Verde (+238)" , num : 238 } ,
	"ky": { name : "Cayman Islands (+1)" , num : 1 } ,
	"cf": { name : "Central Africa (+236)" , num : 236 } ,
	"td": { name : "Chad (+235)" , num : 235 } ,
	"cl": { name : "Chile (+56)" , num : 56 } ,
	"cn": { name : "China (+86)" , num : 86 } ,
	"co": { name : "Colombia (+57)" , num : 57 } ,
	"km": { name : "Comoros (+269)" , num : 269 } ,
	"cg": { name : "Congo (+242)" , num : 242 } ,
	"cd": { name : "Congo, Dem Rep (+243)" , num : 243 } ,
	"cr": { name : "Costa Rica (+506)" , num : 506 } ,
	"hr": { name : "Croatia (+385)" , num : 385 } ,
	"cy": { name : "Cyprus (+357)" , num : 357 } ,
	"cz": { name : "Czech Republic (+420)" , num : 420 } ,
	"dk": { name : "Denmark (+45)" , num : 45 } ,
	"dj": { name : "Djibouti (+253)" , num : 253 } ,
	"dm": { name : "Dominica (+1)" , num : 1 } ,
	"do": { name : "Dominican Republic (+1)" , num : 1 } ,
	"eg": { name : "Egypt (+20)" , num : 20 } ,
	"sv": { name : "El Salvador (+503)" , num : 503 } ,
	"gq": { name : "Equatorial Guinea (+240)" , num : 240 } ,
	"ee": { name : "Estonia (+372)" , num : 372 } ,
	"et": { name : "Ethiopia (+251)" , num : 251 } ,
	"fo": { name : "Faroe Islands (+298)" , num : 298 } ,
	"fj": { name : "Fiji (+679)" , num : 679 } ,
	"fi": { name : "Finland/Aland Islands (+358)" , num : 358 } ,
	"fr": { name : "France (+33)" , num : 33 } ,
	"gf": { name : "French Guiana (+594)" , num : 594 } ,
	"pf": { name : "French Polynesia (+689)" , num : 689 } ,
	"ga": { name : "Gabon (+241)" , num : 241 } ,
	"gm": { name : "Gambia (+220)" , num : 220 } ,
	"ge": { name : "Georgia (+995)" , num : 995 } ,
	"de": { name : "Germany (+49)" , num : 49 } ,
	"gh": { name : "Ghana (+233)" , num : 233 } ,
	"gi": { name : "Gibraltar (+350)" , num : 350 } ,
	"gr": { name : "Greece (+30)" , num : 30 } ,
	"gl": { name : "Greenland (+299)" , num : 299 } ,
	"gd": { name : "Grenada (+1)" , num : 1 } ,
	"gp": { name : "Guadeloupe (+590)" , num : 590 } ,
	"gu": { name : "Guam (+1)" , num : 1 } ,
	"gt": { name : "Guatemala (+502)" , num : 502 } ,
	"gn": { name : "Guinea (+224)" , num : 224 } ,
	"gy": { name : "Guyana (+592)" , num : 592 } ,
	"ht": { name : "Haiti (+509)" , num : 509 } ,
	"hn": { name : "Honduras (+504)" , num : 504 } ,
	"hk": { name : "Hong Kong (+852)" , num : 852 } ,
	"hu": { name : "Hungary (+36)" , num : 36 } ,
	"is": { name : "Iceland (+354)" , num : 354 } ,
	"in": { name : "India (+91)" , num : 91 } ,
	"id": { name : "Indonesia (+62)" , num : 62 } ,
	"iq": { name : "Iraq (+964)" , num : 964 } ,
	"ie": { name : "Ireland (+353)" , num : 353 } ,
	"il": { name : "Israel (+972)" , num : 972 } ,
	"it": { name : "Italy (+39)" , num : 39 } ,
	"jm": { name : "Jamaica (+1)" , num : 1 } ,
	"jp": { name : "Japan (+81)" , num : 81 } ,
	"jo": { name : "Jordan (+962)" , num : 962 } ,
	"ke": { name : "Kenya (+254)" , num : 254 } ,
	"kr": { name : "Korea, Republic of (+82)" , num : 82 } ,
	"kw": { name : "Kuwait (+965)" , num : 965 } ,
	"kg": { name : "Kyrgyzstan (+996)" , num : 996 } ,
	"la": { name : "Laos (+856)" , num : 856 } ,
	"lv": { name : "Latvia (+371)" , num : 371 } ,
	"lb": { name : "Lebanon (+961)" , num : 961 } ,
	"ls": { name : "Lesotho (+266)" , num : 266 } ,
	"lr": { name : "Liberia (+231)" , num : 231 } ,
	"ly": { name : "Libya (+218)" , num : 218 } ,
	"li": { name : "Liechtenstein (+423)" , num : 423 } ,
	"lt": { name : "Lithuania (+370)" , num : 370 } ,
	"lu": { name : "Luxembourg (+352)" , num : 352 } ,
	"mo": { name : "Macao (+853)" , num : 853 } ,
	"mk": { name : "Macedonia (+389)" , num : 389 } ,
	"mg": { name : "Madagascar (+261)" , num : 261 } ,
	"mw": { name : "Malawi (+265)" , num : 265 } ,
	"my": { name : "Malaysia (+60)" , num : 60 } ,
	"mv": { name : "Maldives (+960)" , num : 960 } ,
	"ml": { name : "Mali (+223)" , num : 223 } ,
	"mt": { name : "Malta (+356)" , num : 356 } ,
	"mq": { name : "Martinique (+596)" , num : 596 } ,
	"mr": { name : "Mauritania (+222)" , num : 222 } ,
	"mu": { name : "Mauritius (+230)" , num : 230 } ,
	"mx": { name : "Mexico (+52)" , num : 52 } ,
	"mc": { name : "Monaco (+377)" , num : 377 } ,
	"mn": { name : "Mongolia (+976)" , num : 976 } ,
	"me": { name : "Montenegro (+382)" , num : 382 } ,
	"ms": { name : "Montserrat (+1)" , num : 1 } ,
	"ma": { name : "Morocco/Western Sahara (+212)" , num : 212 } ,
	"mz": { name : "Mozambique (+258)" , num : 258 } ,
	"na": { name : "Namibia (+264)" , num : 264 } ,
	"np": { name : "Nepal (+977)" , num : 977 } ,
	"nl": { name : "Netherlands (+31)" , num : 31 } ,
	"nz": { name : "New Zealand (+64)" , num : 64 } ,
	"ni": { name : "Nicaragua (+505)" , num : 505 } ,
	"ne": { name : "Niger (+227)" , num : 227 } ,
	"ng": { name : "Nigeria (+234)" , num : 234 } ,
	"no": { name : "Norway (+47)" , num : 47 } ,
	"om": { name : "Oman (+968)" , num : 968 } ,
	"pk": { name : "Pakistan (+92)" , num : 92 } ,
	"ps": { name : "Palestinian Territory (+970)" , num : 970 } ,
	"pa": { name : "Panama (+507)" , num : 507 } ,
	"py": { name : "Paraguay (+595)" , num : 595 } ,
	"pe": { name : "Peru (+51)" , num : 51 } ,
	"ph": { name : "Philippines (+63)" , num : 63 } ,
	"pl": { name : "Poland (+48)" , num : 48 } ,
	"pt": { name : "Portugal (+351)" , num : 351 } ,
	"pr": { name : "Puerto Rico (+1)" , num : 1 } ,
	"qa": { name : "Qatar (+974)" , num : 974 } ,
	"re": { name : "Reunion/Mayotte (+262)" , num : 262 } ,
	"ro": { name : "Romania (+40)" , num : 40 } ,
	"ru": { name : "Russia/Kazakhstan (+7)" , num : 7 } ,
	"rw": { name : "Rwanda (+250)" , num : 250 } ,
	"ws": { name : "Samoa (+685)" , num : 685 } ,
	"sm": { name : "San Marino (+378)" , num : 378 } ,
	"sa": { name : "Saudi Arabia (+966)" , num : 966 } ,
	"sn": { name : "Senegal (+221)" , num : 221 } ,
	"rs": { name : "Serbia (+381)" , num : 381 } ,
	"sc": { name : "Seychelles (+248)" , num : 248 } ,
	"sl": { name : "Sierra Leone (+232)" , num : 232 } ,
	"sg": { name : "Singapore (+65)" , num : 65 } ,
	"sk": { name : "Slovakia (+421)" , num : 421 } ,
	"si": { name : "Slovenia (+386)" , num : 386 } ,
	"za": { name : "South Africa (+27)" , num : 27 } ,
	"es": { name : "Spain (+34)" , num : 34 } ,
	"lk": { name : "Sri Lanka (+94)" , num : 94 } ,
	"kn": { name : "St Kitts and Nevis (+1)" , num : 1 } ,
	"lc": { name : "St Lucia (+1)" , num : 1 } ,
	"vc": { name : "St Vincent Grenadines (+1)" , num : 1 } ,
	"sd": { name : "Sudan (+249)" , num : 249 } ,
	"sr": { name : "Suriname (+597)" , num : 597 } ,
	"sz": { name : "Swaziland (+268)" , num : 268 } ,
	"se": { name : "Sweden (+46)" , num : 46 } ,
	"ch": { name : "Switzerland (+41)" , num : 41 } ,
	"tw": { name : "Taiwan (+886)" , num : 886 } ,
	"tj": { name : "Tajikistan (+992)" , num : 992 } ,
	"tz": { name : "Tanzania (+255)" , num : 255 } ,
	"th": { name : "Thailand (+66)" , num : 66 } ,
	"tg": { name : "Togo (+228)" , num : 228 } ,
	"to": { name : "Tonga (+676)" , num : 676 } ,
	"tt": { name : "Trinidad and Tobago (+1)" , num : 1 } ,
	"tn": { name : "Tunisia (+216)" , num : 216 } ,
	"tr": { name : "Turkey (+90)" , num : 90 } ,
	"tc": { name : "Turks and Caicos Islands (+1)" , num : 1 } ,
	"ug": { name : "Uganda (+256)" , num : 256 } ,
	"ua": { name : "Ukraine (+380)" , num : 380 } ,
	"ae": { name : "United Arab Emirates (+971)" , num : 971 } ,
	"gb": { name : "United Kingdom (+44)" , num : 44 } ,
	"us": { name : "United States (+1)" , num : 1 } ,
	"uy": { name : "Uruguay (+598)" , num : 598 } ,
	"uz": { name : "Uzbekistan (+998)" , num : 998 } ,
	"ve": { name : "Venezuela (+58)" , num : 58 } ,
	"vn": { name : "Vietnam (+84)" , num : 84 } ,
	"vg": { name : "Virgin Islands, British (+1)" , num : 1 } ,
	"vi": { name : "Virgin Islands, U.S. (+1)" , num : 1 } ,
	"ye": { name : "Yemen (+967)" , num : 967 } ,
	"zm": { name : "Zambia (+260)" , num : 260 } ,
	"zw": { name : "Zimbabwe (+263)" , num : 263 } ,
}
export function getHttpCodeDescription (statusCode)  {
	const httpCodesDescriptions = {
		"100": "Continue",
		"101": "Switching Protocols",
		"102": "Processing (WebDAV; RFC 2518)",
		"103": "Early Hints (RFC 8297)",
		"200": "OK",
		"201": "Created",
		"202": "Accepted",
		"203": "Non-Authoritative Information (since HTTP/1.1)",
		"204": "No Content",
		"205": "Reset Content",
		"206": "Partial Content (RFC 7233)",
		"207": "Multi-Status (WebDAV; RFC 4918)",
		"208": "Already Reported (WebDAV; RFC 5842)",
		"226": "IM Used (RFC 3229)",
		"300": "Multiple Choices",
		"301": "Moved Permanently",
		"302": "Found (Previously Moved temporarily )",
		"303": "See Other (since HTTP/1.1)",
		"304": "Not Modified (RFC 7232)",
		"305": "Use Proxy (since HTTP/1.1)",
		"306": "Switch Proxy",
		"307": "Temporary Redirect (since HTTP/1.1)",
		"308": "Permanent Redirect (RFC 7538)",
		// "404": "error on Wikipedia",
		"400": "Bad Request",
		"401": "Unauthorized (RFC 7235)",
		"402": "Payment Required",
		"403": "Forbidden",
		"404": "Not Found",
		"405": "Method Not Allowed",
		"406": "Not Acceptable",
		"407": "Proxy Authentication Required (RFC 7235)",
		"408": "Request Timeout",
		"409": "Conflict",
		"410": "Gone",
		"411": "Length Required",
		"412": "Precondition Failed (RFC 7232)",
		"413": "Payload Too Large (RFC 7231)",
		"414": "URI Too Long (RFC 7231)",
		"415": "Unsupported Media Type (RFC 7231)",
		"416": "Range Not Satisfiable (RFC 7233)",
		"417": "Expectation Failed",
		"418": "I'm a teapot (RFC 2324, RFC 7168)",
		"421": "Misdirected Request (RFC 7540)",
		"422": "Unprocessable Entity (WebDAV; RFC 4918)",
		"423": "Locked (WebDAV; RFC 4918)",
		"424": "Failed Dependency (WebDAV; RFC 4918)",
		"425": "Too Early (RFC 8470)",
		"426": "Upgrade Required",
		"428": "Precondition Required (RFC 6585)",
		"429": "Too Many Requests (RFC 6585)",
		"431": "Request Header Fields Too Large (RFC 6585)",
		"451": "Unavailable For Legal Reasons (RFC 7725)",
		"500": "Internal Server Error",
		"501": "Not Implemented",
		"502": "Bad Gateway",
		"503": "Service Unavailable",
		"504": "Gateway Timeout",
		"505": "HTTP Version Not Supported",
		"506": "Variant Also Negotiates (RFC 2295)",
		"507": "Insufficient Storage (WebDAV; RFC 4918)",
		"508": "Loop Detected (WebDAV; RFC 5842)",
		"510": "Not Extended (RFC 2774)",
		"511": "Network Authentication Required (RFC 6585)",
		// "103": "Checkpoint",
		"218": "This is fine (Apache Web Server)",
		"419": "Page Expired (Laravel Framework)",
		"420": "Method Failure (Spring Framework)",
		// "420": "Enhance Your Calm (Twitter)",
		"430": "Request Header Fields Too Large (Shopify)",
		"450": "Blocked by Windows Parental Controls (Microsoft)",
		"498": "Invalid Token (Esri)",
		// "499": "Token Required (Esri)",
		"509": "Bandwidth Limit Exceeded (Apache Web Server/cPanel)",
		"526": "Invalid SSL Certificate",
		"529": "Site is overloaded",
		"530": "Site is frozen",
		"598": "(Informal convention) Network read timeout error",
		"440": "Login Time-out",
		"449": "Retry With",
		// "451": "Redirect",
		"444": "No Response",
		"494": "Request header too large",
		"495": "SSL Certificate Error",
		"496": "SSL Certificate Required",
		"497": "HTTP Request Sent to HTTPS Port",
		"499": "Client Closed Request",
		"520": "Web Server Returned an Unknown Error",
		"521": "Web Server Is Down",
		"522": "Connection Timed Out",
		"523": "Origin Is Unreachable",
		"524": "A Timeout Occurred",
		"525": "SSL Handshake Failed",
		// "526": "Invalid SSL Certificate",
		"527": "Railgun Error"
	}

	return httpCodesDescriptions[statusCode];
}
export function getLocationList(LOCATION_STATUS = 'active' ) {
	
 
	let response = null 
	switch (LOCATION_STATUS) {
		case 'all':
			response = locations; 
			break
		case 'active' : 
			response = locations.filter( x=> x.isActive === true );
			break
		case 'inactive' : 
			response = locations.filter( x=> x.isActive === false ) 
			break
		
	}
	 

	return response;
}
export function getLocationData(region_server_name) {
	

	const result = locations.find(({name}) => name === region_server_name);


	return result;


}

export function getOpsgenieRegionName(ID){
	const d = opsgenieRegions.find(x=> x.id === ID);
 
	return typeof d !=='undefined' ? d.name : null;
}

export function getIncidentEvents(ID){

	if(typeof ID ==='undefined' || ID === null || ID === '' ) return incidentEvents;

	const d = incidentEvents.find(x=> x.id === ID);
 
	return typeof d !=='undefined' ? d.name : null;
}

export function getMaintenanceEvents(ID){

	if(typeof ID ==='undefined' || ID === null || ID === '' ) return maintenanceEvents;

	const d = maintenanceEvents.find(x=> x.id === ID);
 
	return typeof d !=='undefined' ? d.name : null;
}

export function diffDates(from, to) {
	const ms = moment(to).diff(moment(from));
	return moment.duration(ms);

	
}

export function durationToString(durationObject){

	let string = [];
	if(durationObject._data.months > 0) 
		string.push(durationObject._data.months + (durationObject._data.months=== 1 ? 'month' : ' months'))
	if(durationObject._data.days > 0) 
		string.push(durationObject._data.days + (durationObject._data.days=== 1 ? 'day' : ' days'))
	if(durationObject._data.hours > 0) 
		string.push(durationObject._data.hours + (durationObject._data.hours=== 1 ? 'hour' : ' hours'))
	if(durationObject._data.minutes > 0) 
		string.push(durationObject._data.minutes + (durationObject._data.minutes=== 1 ? 'minute' : ' minutes'))
	// if(durationObject._data.seconds > 0) 
	// 	string.push(durationObject._data.seconds + (durationObject._data.seconds=== 1 ? 'second' : ' seconds'))
	
	string = string.join(', ')
	string = string.replace(/,([^,]*)$/, ' and' + '$1') 
	return string ;
}


export function getStatusPageAlertChannels(ID){

	if(typeof ID ==='undefined' || ID === null || ID === '' ) return statusPageAlertChannels;

	const d = statusPageAlertChannels.find(x=> x.id === ID);
 
	return typeof d !=='undefined' ? d.name : null;
}

export function getPhonePrefixes(ID){

	if(typeof ID ==='undefined' || ID === null || ID === '' ) return phonePrefixe;

	return  phonePrefixe[ID]  
}




 