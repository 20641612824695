import React, { Component } from 'react'

import axios from 'axios'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import Favicon from 'react-favicon';
import {Link} from 'react-router-dom'
import psl from 'psl';

import LoadingSpinner from '../../Layout/LoadingSpinner/LoadingSpinner'
import Navbar from '../../Layout/Navbar/Navbar'
import StatusIndicator from '../../Layout/StatusIndicator/StatusIndicator';
import Footer from '../../Layout/Footer/Footer'
import {getIncidentEvents , diffDates , durationToString, getMaintenanceEvents} from './../../../utils/helper'
// Invoke the global state
import AppContext from './../../../context/app-context'
// import css design 
import './incidents.css'
// import icons 
import { ReactComponent as IncidentIcon } from './../../../assets/icons/flash.svg'

export default class Incidents extends Component{

	static contextType = AppContext
    signal = axios.CancelToken.source();

    constructor(props){
        super(props)
        this.state = {
            statusPageType: 'hosted-subdomain',//hosted-subdomain or custom-domain
			statusPageDomain: '',//'gdfgdfgdf',
			statusPageNativeDomain: '',
			statusPageHomePageLink: null,
			statusPageLogoUrl: null,
			statusPageName: null,
			statusPageChartColor: null,
			statusPageBackgroundColor: null,
            globalStatus: 'up',
			averagePingsDataPerMonitor: {},
			regex_hostedDomain: "^([A-Za-z0-9\-]+)\.(odown.io)",
			regex_hostedDomain_secondary: "^([A-Za-z0-9\-]+)\.(odown.com)",
            isLoading : false ,
            incidentList : [],
			isCompletedLoading : false ,
            isLoading_statusPageInfo : false ,
            notFound: false,
			pings_1d : [],
			allowSubscriptions : false ,
			allowedChannelsSubs : [] , 
			activeRanges : ['12months' , '3months' , '1month' , '1week' , '24hours'],
			chart_parameters : {},
			showOpenIncidents: false ,
			availableDateRanges: [
				{
					name: "12 months",
					handle : '12months' ,
					nb_days: 365,
					selected: false
				},
				{
					name: "3 months",
					handle : '3months' ,
					nb_days: 91,
					selected: false
				},
				{
					name: "1 month",
					handle : '1month' ,
					nb_days: 30,
					selected: false
				},
				{
					name: "1 week",
					handle : '1week' ,
					nb_days: 7,
					selected: false
				},
				{
					name: "24 hours",
					handle : '24hours' ,
					nb_days: 1,
					selected: true
				}
			],
        }   

        this.getIncidents = this.getIncidents.bind(this);
    }

    getStatusPageInfo() {


		this.setState({
			isLoading_statusPageInfo: true
		});

		
		const globalStatusPageData = this.context.getStatusPageData();

		if(typeof globalStatusPageData!=='undefined' && globalStatusPageData!==null){
			this.setState({

				monitorItems : globalStatusPageData.monitorItems,
				statusPageHomePageLink : globalStatusPageData.statusPageHomePageLink,
				statusPageLogoUrl : globalStatusPageData.statusPageLogoUrl,
				statusPageName : globalStatusPageData.statusPageName,
				statusPageChartColor : globalStatusPageData.statusPageChartColor,
				statusPageBackgroundColor : globalStatusPageData.statusPageBackgroundColor,
				globalStatus : globalStatusPageData.globalStatus,
				pings_1d : globalStatusPageData.pings_1d,
				averagePingsDataPerMonitor : globalStatusPageData.averagePingsDataPerMonitor,
				monitorInfo : globalStatusPageData.monitorInfo,
				isLoading_statusPageInfo : false ,
				allowSubscriptions : globalStatusPageData.allowSubscriptions,
				allowedChannelsSubs : globalStatusPageData.allowedChannelsSubs,
				activeRanges : globalStatusPageData.activeRanges,
				chart_parameters : globalStatusPageData.chart_parameters,
				showOpenIncidents : globalStatusPageData.showOpenIncidents,
				availableDateRanges : globalStatusPageData.availableDateRanges,
				statusPageNativeDomain : globalStatusPageData.statusPageNativeDomain,
			})

			return 
		}

		axios
			.get(`${process.env.REACT_APP_API_URL}public/statuspages/${this.state.statusPageDomain}`, {
				params: {
					//w_data: true,
					type: this.state.statusPageType
				},
				cancelToken: this.signal.token
			})
			.then(res => res.data)
			.then(data => {

				let stateItems = {}

				if (typeof data.monitors !== 'undefined' && data.monitors !== null && data.monitors.length > 0) {
					stateItems.monitorItems = data.monitors;
					stateItems.statusPageHomePageLink = data.homepage_link;
					stateItems.statusPageLogoUrl = data.logo;
					stateItems.statusPageName = data.name;
					stateItems.statusPageChartColor = data.color;
					stateItems.statusPageBackgroundColor = data.background_color;
					stateItems.allowSubscriptions = data.allow_subscriptions;
					stateItems.activeRanges = Object.keys(data.chart_options).filter(x => data.chart_options[x].is_active === true);
					stateItems.chart_parameters = data.chart_options;
					stateItems.showOpenIncidents = data.display_open_incidents;
					stateItems.allowedChannelsSubs = data.allowed_channel_subs;
					stateItems.statusPageAccessInfo = {
						type : this.state.statusPageType,
						domain : this.state.statusPageDomain,
					}

					// fetch all monitors (one by one )
					let downMonitorCount = 0;
					Promise.all(data.monitors.map(item => {

						if (item.is_down === true) downMonitorCount++;

						// update 24 hours pings ( pings_1d)
						let pings_1d = this.state.pings_1d
						pings_1d.push({
							monitorId: item.id,
							data: item.pings
						});
						stateItems.pings_1d = pings_1d;
						 

						 
					}));


					// validate the global status of all monitors in the current statuspage
					if (downMonitorCount === 0) stateItems.globalStatus = 'up';
					else if (downMonitorCount === data.monitors.length) stateItems.globalStatus = 'down';
					else if (downMonitorCount < data.monitors.length && downMonitorCount > 0) stateItems.globalStatus = 'partial';

				 
					stateItems.monitorInfo = data;


					// ? Change the default selected range
					let tryCount = 0 ; 
					 
					stateItems.availableDateRanges = this.state.availableDateRanges
						.sort((a,b) => (a.nb_days > b.nb_days) ? 1 : ((b.nb_days > a.nb_days) ? -1 : 0))	
						.map( x=> {
						if(stateItems.activeRanges.includes(x.handle) && tryCount === 0) {
							x.selected = true ;
							tryCount++;
							return x;
						}else {
							x.selected = false 
							return x
						}
					})


					// ? if the status page settings has a homepage url then we extract the native domain 
					// ? name from it else we let the preview native domain name detecter  
					if(typeof data.homepage_link!='undefined' && data.homepage_link!==null){

						const url = data.homepage_link
						// console.log('url : ' , url )
						const hostname = new URL(url).hostname;
						// console.log('hostname : ' , hostname )
						
						// Get the native domain name from the url (Parse domain without subdomain) 
						var parsedURL = psl.parse(hostname);
						// console.log('parsedURL : ' , parsedURL )
						stateItems.statusPageNativeDomain = parsedURL.domain;
					}
				}

				stateItems.isLoading_statusPageInfo = false;
				this.setState(stateItems , () => {
					// ! Save as a global state 
					this.context.setStatusPageData(stateItems) 
				});
			})
			.catch(error => {

				let stateItems = {}
				stateItems.notFound = true;

				if (axios.isCancel(error)) {
				} else {
					stateItems.isLoading_statusPageInfo = false
				}


				this.setState(stateItems);
			})
	}

    getIncidents(){
        this.setState({
			isLoading : true
		});

        axios
			.get(`${process.env.REACT_APP_API_URL}public/statuspages/${this.state.statusPageDomain}/incidents`, {
				params: {
					//w_data: true,
					type: this.state.statusPageType
				},
				cancelToken: this.signal.token
			})
			.then(res => res.data)
			.then(data => {

				let stateItems = {}

				if(typeof data.events!=='undefined' && data.events!==null && data.events.length > 0) {
							
					data.events.sort((a,b) => moment(b.created_at).unix() - moment(a.created_at).unix())

				}

				stateItems.incidentList = data

				stateItems.isLoading = false;
				stateItems.isCompletedLoading = true;
				this.setState(stateItems);
			})
			.catch(error => {

				let stateItems = {}

				if (axios.isCancel(error)) {
				} else {
					stateItems.isLoading = false
					stateItems.isCompletedLoading = true;
				}


				this.setState(stateItems);
			})

    }


	getSelectedUrl() {

		return new Promise(async (resolve, reject) => {

			// Get the selected url 
			const url = window.location.href;
			const hostname = new URL(url).hostname;
			let statusPageType = this.state.statusPageType;

			// Get the native domain name from the url (Parse domain without subdomain) 
			var parsedURL = psl.parse(hostname);


			if (!hostname.match(this.state.regex_hostedDomain) && !hostname.match(this.state.regex_hostedDomain_secondary)) statusPageType = 'custom-domain';

			this.setState({
				statusPageType: statusPageType,
				statusPageDomain: hostname,
				statusPageNativeDomain: parsedURL.domain
			}, () => {
				resolve()
			})
		})
	}

    async componentDidMount(){

		// Get Status Page info
		this.getSelectedUrl()
		.then( async response => {

			this.getStatusPageInfo();
			 // Get the last 30 days incidents :  
			 this.getIncidents();
		})


       
       
    }

    render(){



        return (
        <React.Fragment>
				<Favicon url={`https://s2.googleusercontent.com/s2/favicons?domain=https://${this.state.statusPageNativeDomain}`} />
				<Helmet>
					<title>{`${typeof this.state.statusPageName !== 'undefined' && this.state.statusPageName !== null ? this.state.statusPageName : 'Odown'} Status`}</title>
				</Helmet>
				<div className="global-header" style={{ backgroundColor: this.state.statusPageBackgroundColor }} >
					{this.state.notFound === false && this.state.isLoading_statusPageInfo === false &&
						<Navbar
							logo_url={this.state.statusPageLogoUrl}
							homepageLink={this.state.statusPageHomePageLink}
							name={this.state.statusPageName}
							backgroundColor={this.state.statusPageBackgroundColor}
							allowSubscriptions={this.state.allowSubscriptions}
							allowedChannelsSubs={this.state.allowedChannelsSubs} />
					}
					{
						this.state.notFound === false
						&& <div className="header-wrapper">
							<div className="header-content">
								<div className="container">
									<div className={`white-card status-wrapper ${this.state.isLoading_statusPageInfo ? '' : (this.state.globalStatus === 'down' ? 'is-down' : (this.state.globalStatus === 'partial' ? 'is-partial-down' : 'is-up'))}`}>
										{
											this.state.isLoading_statusPageInfo
												? <div className="loading-wrapper"><LoadingSpinner /></div>
												:
												<>
													<div className="status--icon_flag">
														<StatusIndicator 
															status={
																this.state.globalStatus === 'up'
																? 'success'
																: (
																	this.state.globalStatus === 'partial'
																	? 'warning'
																	: 'danger'
																)
															}
															title={``}
															size={`medium`} />
													</div>
													<div className="status--tx-content">
														<div className="status--title">Current status</div>
														<div className="status--global_state_vl" >
															{
																this.state.globalStatus === 'down'
																	? 'All services are down'
																	: (
																		this.state.globalStatus === 'partial'
																			? 'Some services are down'
																			: 'All services are online'
																	)

															}
														</div>
														<div className="status--current-time">
															{
																`As of ${moment().format('DD MMMM YYYY, hh:mm A')}`
															}
														</div>
													</div>
													<div className="incident-link" >
														<Link to={`/incidents`} ><IncidentIcon /> Incident History</Link></div>
												</>
										}
									</div>
								</div>
							</div>
							<div className="header-bottom-polygon" >
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"  >
								<polygon fill="white" points="0,100 100,0 100,100" ></polygon>
							</svg>	
							</div>		
						</div>
					}
				</div>
				<div className="body-wrapper">
					<div className="body-content">
						<div className="container">
							{
								this.state.isLoading 
								? <div className="loading-wrapper"><LoadingSpinner /></div>
								: (
									
									this.state.isCompletedLoading && this.state.incidentList.length <=0 
										? <div className="white-card not_found-container" >
											<h1>No incidents reported </h1>
											<span></span>
										</div>
										: <div className="incidents-items" >
											{
												 
												this.state.incidentList.map(( incidentItem , index) => {

													if(typeof incidentItem.events!=='undefined' && incidentItem.events!==null && incidentItem.events.length > 0) {
							
														incidentItem.events.sort((a,b) => moment(b.created_at).unix() - moment(a.created_at).unix())
									
													}
													let isResolved = false ;
													let resolvedPeriod = null ;
													const resolvedEvent = incidentItem!==null 
															? incidentItem.events.find(x => x.type.toLowerCase() === 'resolved') 
															: null;
													const firstEvent = incidentItem!==null 
															? incidentItem.events[incidentItem.events.length - 1] 
															: null;
													if( typeof resolvedEvent!=='undefined' && resolvedEvent!==null) {
														isResolved = true ;
														resolvedPeriod = diffDates(moment(firstEvent.created_at).format() , moment(resolvedEvent.created_at).format())
														resolvedPeriod = durationToString(resolvedPeriod)
														
														
													}
													
													return(
														<div key={index} className="incidents-item white-card" >
															<div className="incident_item-wrapper">
																<div className="incident--name">
																	{  incidentItem.is_scheduled === true && <span className="scheduled-tag" >Scheduled</span> }
																	<Link to={`/incidents/${incidentItem.id}`}>{ incidentItem.name }</Link></div>
																<div className="incident--body">
																	{
																		isResolved && resolvedPeriod!==null && resolvedPeriod!=='' && 
																		<div className="incident-resolved_period">
																			{
																				`Resolved after about ${resolvedPeriod}`
																			}
																		</div>
																	}
																	<div className="incident-events">
																		<ul>
																		{
																			incidentItem.events.map( (item , index) => {
																				return (
																					<li className="" key={index}>
																						<div className="historyEvents--timeline">
																							<span className="timeline-icon"><i className="status-icon"></i></span>
																						</div>
																						<div className="historyEvents--item">
																							<div className={`event-type ${item.type.toLowerCase()}`}>{incidentItem.is_scheduled === true ? getMaintenanceEvents(item.type) : getIncidentEvents(item.type)}</div>
																							<div className="event-msg_content">
																								<div className="msg" >{item.message}</div>
																								<div className="outline-info" >
																									<span className="outline_info-date" >{moment(item.created_at).format('MMM DD, hh:mm A')}</span>
																								</div>
																							</div>
																						</div>
																					</li>
																				)
																			})
																		}
																		</ul>
																	</div>
																</div>
																
															</div>
														</div>
													)
												})
											 
											}
										</div>
								)


                            }
						</div>
					</div>
				</div>
				<Footer />
				 
			</React.Fragment>
        )
    }
}