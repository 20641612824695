import React, { Component } from 'react'

import axios from 'axios'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import Favicon from 'react-favicon';
import psl from 'psl';
import { Link } from 'react-router-dom'

import LoadingSpinner from '../../Layout/LoadingSpinner/LoadingSpinner'
import RechartLineChart, { RechartBarChart, RechartAreaChart } from "../../Layout/Rechart/Rechart"
import Navbar from '../../Layout/Navbar/Navbar'
import StatusIndicator from '../../Layout/StatusIndicator/StatusIndicator';
import Footer from '../../Layout/Footer/Footer'
import LastActiveIncidents from './../../Layout/Header-notifications/ActiveIncident'

// Invoke the global state
import AppContext from './../../../context/app-context'
// import icons 
import { ReactComponent as IncidentIcon } from './../../../assets/icons/flash.svg'

export default class MainStatusPage extends Component {

	// permission to use the global state 
	static contextType = AppContext;
	signal = axios.CancelToken.source();

	constructor (props) {
		super(props);
		this.state = {
			statusPageType: 'hosted-subdomain',//hosted-subdomain or custom-domain
			statusPageDomain: '',//'gdfgdfgdf',
			statusPageNativeDomain: '',
			statusPageHomePageLink: null,
			statusPageLogoUrl: null,
			statusPageName: null,
			statusPageChartColor: null,
			statusPageBackgroundColor: null,
			isLoading_statusPageInfo: false,
			notFound: false,
			monitorItems: [],

			monitorInfo: null,
			isLoadingMonitors: {},
			monitorChats_last24h: {},
			monitorChats_last12Months: {},
			availableDateRanges: [
				{
					name: "12 months",
					handle: '12months',
					nb_days: 365,
					selected: false
				},
				{
					name: "3 months",
					handle: '3months',
					nb_days: 91,
					selected: false
				},
				{
					name: "1 month",
					handle: '1month',
					nb_days: 30,
					selected: false
				},
				{
					name: "1 week",
					handle: '1week',
					nb_days: 7,
					selected: false
				},
				{
					name: "24 hours",
					handle: '24hours',
					nb_days: 1,
					selected: true
				}
			],

			pings_1d: [],
			pings_7d: [], // last 7 days 
			pings_30d: [], // last 30 days 
			pings_91d: [], // last 3 months 
			pings_365d: [], // last year
			test: false,
			globalStatus: 'up',
			averagePingsDataPerMonitor: {},
			regex_hostedDomain: "^([A-Za-z0-9\-]+)\.(odown.io)",
			regex_hostedDomain_secondary: "^([A-Za-z0-9\-]+)\.(odown.com)",
			allowSubscriptions: false,
			allowedChannelsSubs: [],
			activeRanges: ['12months', '3months', '1month', '1week', '24hours'],
			chart_parameters: {},
			showOpenIncidents: {
				realtime: false,
				scheduled: false
			},
			outageRanges: []


		};

		this.handleChange__selectRangeDate = this.handleChange__selectRangeDate.bind(this)
		this.getSelectedUrl = this.getSelectedUrl.bind(this)

	}

	/**
	 * 
	 */
	getStatusPageInfo () {


		this.setState({
			isLoading_statusPageInfo: true
		});

		const globalStatusPageData = this.context.getStatusPageData();


		if (typeof globalStatusPageData !== 'undefined' && globalStatusPageData !== null) {
			this.setState({

				monitorItems: globalStatusPageData.monitorItems,
				statusPageHomePageLink: globalStatusPageData.statusPageHomePageLink,
				statusPageLogoUrl: globalStatusPageData.statusPageLogoUrl,
				statusPageName: globalStatusPageData.statusPageName,
				statusPageChartColor: globalStatusPageData.statusPageChartColor,
				statusPageBackgroundColor: globalStatusPageData.statusPageBackgroundColor,
				globalStatus: globalStatusPageData.globalStatus,
				pings_1d: globalStatusPageData.pings_1d,
				averagePingsDataPerMonitor: globalStatusPageData.averagePingsDataPerMonitor,
				monitorInfo: globalStatusPageData.monitorInfo,
				isLoading_statusPageInfo: false,
				allowSubscriptions: globalStatusPageData.allowSubscriptions,
				allowedChannelsSubs: globalStatusPageData.allowedChannelsSubs,
				activeRanges: globalStatusPageData.activeRanges,
				chart_parameters: globalStatusPageData.chart_parameters,
				showOpenIncidents: globalStatusPageData.showOpenIncidents,
				availableDateRanges: globalStatusPageData.availableDateRanges,
				outageRanges: globalStatusPageData.outageRanges,
				statusPageNativeDomain: globalStatusPageData.statusPageNativeDomain,
			})

			Promise.all(globalStatusPageData.monitorItems.map(item => {

				this.getMonitorResults(item.id, 365);
			}));

			return
		}

		console.log('getting main status page info')
		console.log(this.state.statusPageDomain);
		axios
			.get(`${process.env.REACT_APP_API_URL}public/statuspages/${this.state.statusPageDomain}`, {
				params: {
					//w_data: true,
					type: this.state.statusPageType
				},
				cancelToken: this.signal.token
			})
			.then(res => res.data)
			.then(data => {


				let stateItems = {}

				if (typeof data.monitors !== 'undefined' && data.monitors !== null && data.monitors.length > 0) {
					stateItems.monitorItems = data.monitors;
					stateItems.statusPageHomePageLink = data.homepage_link;
					stateItems.statusPageLogoUrl = data.logo;
					stateItems.statusPageName = data.name;
					stateItems.statusPageChartColor = data.color;
					stateItems.statusPageBackgroundColor = data.background_color;
					stateItems.allowSubscriptions = data.allow_subscriptions;
					stateItems.activeRanges = Object.keys(data.chart_options).filter(x => data.chart_options[x].is_active === true);
					stateItems.chart_parameters = data.chart_options;
					stateItems.showOpenIncidents = data.display_open_incidents;
					stateItems.allowedChannelsSubs = data.allowed_channel_subs;
					stateItems.statusPageAccessInfo = {
						type: this.state.statusPageType,
						domain: this.state.statusPageDomain,
					}
					stateItems.outageRanges = data.incidents;

					// fetch all monitors (one by one )
					let downMonitorCount = 0;
					Promise.all(data.monitors.map(item => {

						if (item.is_down === true) downMonitorCount++;


						// update 24 hours pings ( pings_1d)
						let pings_1d = this.state.pings_1d
						pings_1d.push({
							monitorId: item.id,
							data: item.pings
						});
						stateItems.pings_1d = pings_1d;

						// calculate the uptime and responseTime average of 24 ping Data 
						stateItems.averagePingsDataPerMonitor = this.calculateAveragePingsData(this.state.averagePingsDataPerMonitor, item.id, item.pings, '1d');

						this.getMonitorResults(item.id, 365);
					}));


					// validate the global status of all monitors in the current statuspage
					if (downMonitorCount === 0) stateItems.globalStatus = 'up';
					else if (downMonitorCount === data.monitors.length) stateItems.globalStatus = 'down';
					else if (downMonitorCount < data.monitors.length && downMonitorCount > 0) stateItems.globalStatus = 'partial';

					stateItems.monitorInfo = data;

					// ? Change the default selected range
					let tryCount = 0;

					stateItems.availableDateRanges = this.state.availableDateRanges
						.sort((a, b) => (a.nb_days > b.nb_days) ? 1 : ((b.nb_days > a.nb_days) ? -1 : 0))
						.map(x => {
							if (stateItems.activeRanges.includes(x.handle) && tryCount === 0) {
								x.selected = true;
								tryCount++;
								return x;
							} else {
								x.selected = false
								return x
							}
						})

					// ? if the status page settings has a homepage url then we extract the native domain 
					// ? name from it else we let the preview native domain name detecter  
					if (typeof data.homepage_link !== 'undefined' && data.homepage_link !== null && data.homepage_link !== "") {

						const url = data.homepage_link
						console.log('url : ', url)
						const hostname = new URL(url).hostname;
						console.log('hostname : ', hostname)

						// Get the native domain name from the url (Parse domain without subdomain) 
						var parsedURL = psl.parse(hostname);
						// console.log('parsedURL : ' , parsedURL )
						stateItems.statusPageNativeDomain = parsedURL.domain;
					}



				}

				stateItems.isLoading_statusPageInfo = false;
				this.setState(stateItems, () => {
					// ! Save as a global state 
					this.context.setStatusPageData(stateItems)
				});
			})
			.catch(error => {

				//console.log(error);



				let stateItems = {}
				stateItems.notFound = true;

				if (axios.isCancel(error)) {
					//console.log('Error: ', err.message); // => prints: Api is being canceled
				} else {
					stateItems.isLoading_statusPageInfo = false
				}


				this.setState(stateItems);
			})
	}

	/**
	 * 
	 * @param {*} monitorId 
	 * @param {*} delay 
	 */
	getMonitorResults (monitorId, limit = 365) {
		return new Promise(async (resolve, reject) => {

			this.start_monitorLoading(monitorId)

			axios
				.get(`${process.env.REACT_APP_API_URL}public/statuspages/${this.state.statusPageDomain}/monitors/${monitorId}/results`, {
					params: {
						limit: (limit > 365) ? 365 : limit
					},
					cancelToken: this.signal.token
				})
				.then(res => res.data)
				.then(data => {


					let pings_365d = this.state.pings_365d;
					let pings_7d = this.state.pings_7d;
					let pings_30d = this.state.pings_30d;
					let pings_91d = this.state.pings_91d;


					const found_365d = pings_365d.findIndex(x => x.monitorId === monitorId)
					const found_7d = pings_7d.findIndex(x => x.monitorId === monitorId)
					const found_30d = pings_30d.findIndex(x => x.monitorId === monitorId)
					const found_91d = pings_91d.findIndex(x => x.monitorId === monitorId)

					let averagePingsDataPerMonitor = this.state.averagePingsDataPerMonitor;


					if (typeof found_365d === 'undefined' || found_365d < 0) {

						pings_365d.push({
							monitorId: monitorId,
							data: data
						})

						// calculate the average of responsetime and uptime for 365 days range 
						averagePingsDataPerMonitor = this.calculateAveragePingsData(averagePingsDataPerMonitor, monitorId, data, '365d');

					}

					if (typeof found_7d === 'undefined' || found_7d < 0 ||
						typeof found_30d === 'undefined' || found_30d < 0 ||
						typeof found_91d === 'undefined' || found_91d < 0
					) {

						// prepare the rest of pings date range array 
						let data_7d_tmp = [];
						let data_30d_tmp = [];
						let data_91d_tmp = [];

						data.map(item => {

							if (moment(item.date).unix() >= moment().add(-91, 'days').unix()) {
								data_91d_tmp.push(item)
							}
							if (moment(item.date).unix() >= moment().add(-30, 'days').unix()) {
								data_30d_tmp.push(item)
							}
							if (moment(item.date).unix() >= moment().add(-7, 'days').unix()) {
								data_7d_tmp.push(item)
							}
						});
						if (data_7d_tmp.length > 0) {
							pings_7d.push({
								monitorId: monitorId,
								data: data_7d_tmp
							})
							// calculate the average of responsetime and uptime for 7 days range 
							averagePingsDataPerMonitor = this.calculateAveragePingsData(averagePingsDataPerMonitor, monitorId, data_7d_tmp, '7d');
						}
						if (data_30d_tmp.length > 0) {
							pings_30d.push({
								monitorId: monitorId,
								data: data_30d_tmp
							})
							// calculate the average of responsetime and uptime for 30 days range 
							averagePingsDataPerMonitor = this.calculateAveragePingsData(averagePingsDataPerMonitor, monitorId, data_30d_tmp, '30d');
						}
						if (data_91d_tmp.length > 0) {
							pings_91d.push({
								monitorId: monitorId,
								data: data_91d_tmp
							})
							// calculate the average of responsetime and uptime for 91 days range 
							averagePingsDataPerMonitor = this.calculateAveragePingsData(averagePingsDataPerMonitor, monitorId, data_91d_tmp, '91d');
						}

					}

					this.setState({
						pings_365d,
						pings_7d,
						pings_30d,
						pings_91d,
						averagePingsDataPerMonitor
					})

					this.stop_monitorLoading(monitorId)



				})
				.catch(error => {
					this.stop_monitorLoading(monitorId)
				})



		})
	}

	calculateAveragePingsData (averagePingsDataPerMonitor, monitorId, data, timeRange) {
		if (typeof data !== 'undefined') {

			let averageData = data.reduce((accumulator, currentValue) => {
				accumulator = {
					responsetime: (typeof accumulator !== 'undefined' && !isNaN(accumulator.responsetime))
						? parseFloat(accumulator.responsetime) + parseFloat(currentValue.responsetime)
						: parseFloat(currentValue.responsetime),
					uptime: accumulator.uptime + parseFloat(currentValue.uptime)
				}

				return accumulator;
			}, {
				responsetime: 0,
				uptime: 0
			});


			averageData = {
				responsetime: averageData.responsetime / data.length,
				uptime: averageData.uptime / data.length
			}

			let tmp = (typeof averagePingsDataPerMonitor !== 'undefined') ? averagePingsDataPerMonitor : {};

			if (typeof tmp[monitorId] === 'undefined') tmp[monitorId] = {};
			tmp[monitorId][timeRange] = averageData;





			return tmp;
		}
	}

	start_monitorLoading (__monitorId) {
		const isLoadingMonitors = this.state.isLoadingMonitors;
		isLoadingMonitors[__monitorId] = true;
		this.setState({
			isLoadingMonitors
		})
	}

	stop_monitorLoading (__monitorId) {
		const isLoadingMonitors = this.state.isLoadingMonitors;
		isLoadingMonitors[__monitorId] = false;
		this.setState({
			isLoadingMonitors
		})
	}

	handleChange__selectRangeDate (event) {


		const value = event.currentTarget.dataset.value;
		let availableDateRanges = this.state.availableDateRanges;
		// update the status of date filter  
		availableDateRanges = availableDateRanges.map(item => {
			item.selected = item.nb_days === parseFloat(value) ? true : false;
			return item;
		});


		// update the chart depending the selected date range
		const pings_collection = value === 1 ? this.state.pings_1d : this.state.pings_365d;
		const monitorItems = this.state.monitorItems;
		// const pings_1d = this.state.pings_1d;
		// const pings_365d = this.state.pings_365d;
		monitorItems.map(monitorItem => {

			// const found = pings_collection.find(x=> x.monitorId === monitorItem.id && typeof x.data !=='undefined' && x.data !==null && x.data.length > 0);	
			// if(typeof found==='undefined' || found === null || found < 0 ){
			// 	// call api and load the pings 
			// 	this.getMonitorInfo(monitorItem.id , value === 1 ? 5 : 24*60 , value === 1 ? 'last_1d' : 'last_12months');


			// }
		});

		// update the state 
		this.setState({
			availableDateRanges
		})

	}

	/**
	 * 
	 * @returns 
	 */
	getSelectedUrl () {

		return new Promise(async (resolve, reject) => {

			// Get the selected url 
			const url = window.location.href;
			const hostname = new URL(url).hostname;
			let statusPageType = this.state.statusPageType;

			// Get the native domain name from the url (Parse domain without subdomain) 
			var parsedURL = psl.parse(hostname);


			if (!hostname.match(this.state.regex_hostedDomain) && !hostname.match(this.state.regex_hostedDomain_secondary)) statusPageType = 'custom-domain';

			this.setState({
				statusPageType: statusPageType,
				statusPageDomain: hostname,
				statusPageNativeDomain: parsedURL.domain
			}, () => {
				resolve()
			})
		})
	}




	componentDidMount () {
		// Get Status Page info
		this.getSelectedUrl()
			.then(response => {

				this.getStatusPageInfo();
			})
		// 
	}

	render () {

		let { averagePingsDataPerMonitor, statusPageBackgroundColor } = this.state;

		const selectedRangeDate = this.state.availableDateRanges?.find(x => x.selected === true).nb_days;

		let selectedRangePings = this.state.pings_7d;
		if (selectedRangeDate === 30) selectedRangePings = this.state.pings_30d;
		else if (selectedRangeDate === 91) selectedRangePings = this.state.pings_91d;
		else if (selectedRangeDate === 365) selectedRangePings = this.state.pings_365d;


		// selected range option 
		const selectedRangeOption = (typeof this.state.chart_parameters !== 'undefined' && this.state.chart_parameters !== null) ? this.state.chart_parameters[this.state.availableDateRanges.find(x => x.selected === true).handle] : null;



		return (
			<React.Fragment>
				<Favicon url={`https://s2.googleusercontent.com/s2/favicons?domain=https://${this.state.statusPageNativeDomain}`} />
				<Helmet>
					<title>{`${typeof this.state.statusPageName !== 'undefined' && this.state.statusPageName !== null ? this.state.statusPageName : 'Odown'} Status`}</title>
				</Helmet>
				<div className="global-header" style={{ backgroundColor: statusPageBackgroundColor }} >
					{this.state.notFound === false && this.state.isLoading_statusPageInfo === false &&
						<Navbar
							logo_url={this.state.statusPageLogoUrl}
							homepageLink={this.state.statusPageHomePageLink}
							name={this.state.statusPageName}
							backgroundColor={statusPageBackgroundColor}
							allowSubscriptions={this.state.allowSubscriptions}
							allowedChannelsSubs={this.state.allowedChannelsSubs}
						// statusPageDomain={this.state.statusPageDomain}
						// statusPageType={this.state.statusPageType}  
						/>
					}
					{
						this.state.notFound === false
						&& <div className="header-wrapper">
							<div className="header-content">
								<div className="container">
									<div className={`white-card status-wrapper ${this.state.isLoading_statusPageInfo ? '' : (this.state.globalStatus === 'down' ? 'is-down' : (this.state.globalStatus === 'partial' ? 'is-partial-down' : 'is-up'))}`}>
										{
											this.state.isLoading_statusPageInfo
												? <div className="loading-wrapper"><LoadingSpinner /></div>
												:
												<>
													<div className="status--icon_flag">
														<StatusIndicator
															status={
																this.state.globalStatus === 'up'
																	? 'success'
																	: (
																		this.state.globalStatus === 'partial'
																			? 'warning'
																			: 'danger'
																	)
															}
															title={``}
															size={`medium`} />
													</div>
													<div className="status--tx-content">
														<div className="status--title">Current status</div>
														<div className="status--global_state_vl" >
															{
																this.state.globalStatus === 'down'
																	? 'All services are down'
																	: (
																		this.state.globalStatus === 'partial'
																			? 'Some services are down'
																			: 'All services are online'
																	)

															}
														</div>
														<div className="status--current-time">
															{
																`As of ${moment().format('DD MMMM YYYY, hh:mm A')}`
															}
														</div>
													</div>
													<div className="incident-link" >
														<Link to={`/incidents`} ><IncidentIcon /> Incident History</Link></div>
												</>
										}
									</div>
								</div>
							</div>
							<div className="header-bottom-polygon" >
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"  >
									<polygon fill="white" points="0,100 100,0 100,100" ></polygon>
								</svg>
							</div>
						</div>
					}
				</div>
				{
					typeof this.state.statusPageDomain !== 'undefined' && this.state.statusPageDomain !== '' &&
					(this.state.showOpenIncidents.realtime === true || this.state.showOpenIncidents.scheduled === true) &&
					<LastActiveIncidents
						statusPageDomain={this.state.statusPageDomain}
						statusPageType={this.state.statusPageType}
						showOpenIncidents={this.state.showOpenIncidents}
					/>
				}
				<div className="body-wrapper">
					<div className="body-content">
						<div className="container">
							{
								this.state.notFound === true
									? <div className="white-card not_found-container" >
										<h1>Status page not found</h1>
										<span></span>
									</div>
									: <div className="monitors-services ">
										{
											this.state.isLoading_statusPageInfo
												? <div className="loading-wrapper"><LoadingSpinner /></div>
												: <>
													{
														// ? FILTER BAR ( DATE RANGE ) 
														this.state.activeRanges.length > 1 &&
														<div className="date-filter">
															<ul>
																{
																	typeof this.state.availableDateRanges !== 'undefined' &&
																	this.state.availableDateRanges
																		.filter(x => this.state.activeRanges.includes(x.handle))
																		.map((item, index) => {
																			return (
																				<li key={index} className={`selector-item ${item.selected === true ? 'selected' : ''}`} onClick={this.handleChange__selectRangeDate} data-value={item.nb_days}>{item.name}</li>
																			)
																		})
																}
															</ul>
														</div>
													}
													{
														// ? Display the 24 hours charts only if the option is selected from statuspage parameters 
														this.state.activeRanges.includes('24hours') &&
														<div className={`tab--24h ${typeof this.state.availableDateRanges !== 'undefined' && this.state.availableDateRanges.find(x => x.selected === true).nb_days !== 1 ? 'hide' : ''}`}>
															{
																(selectedRangeDate === 1 || this.state.pings_1d.length > 0) && typeof this.state.monitorItems !== 'undefined' && this.state.monitorItems.map((item, index) => {
																	return (
																		<div key={index} className="check-item white-card">
																			<div className="check-item__main-info">
																				<div className="check-item__status">
																					<StatusIndicator
																						status={
																							item.is_down === true
																								? 'danger'
																								: 'success'
																						}
																						title={``} />
																				</div>
																				<div className="check-item__name">{item.name}</div>
																				<div className="check-item__state">
																					{
																						item.is_down === true
																							? <div className="ci-state is-down" >Partial Outage</div>
																							: <div className="ci-state " >Operational</div>
																					}
																				</div>
																			</div>
																			<div className={`check-item__chart ${item.chart_uptime === false && item.chart_responsetime === false ? 'hide' : ''}`}>
																				{
																					this.state.isLoadingMonitors[item.id]
																						? <div className="loading-wrapper "><LoadingSpinner position="center" size="small" /></div>
																						: typeof this.state.pings_1d !== 'undefined' && this.state.pings_1d.length > 0 &&
																						(

																							this.state.pings_1d.find(x => x.monitorId === item.id).data !== undefined
																								?
																								<>
																									{
																										typeof item.chart_uptime !== 'undefined' && item.chart_uptime === true &&
																										<RechartAreaChart
																											chartType='uptime'
																											data={
																												this.state.pings_1d.find(x => x.monitorId === item.id).data.map(item => {
																													return {
																														date: item.date,
																														value: (typeof item !== 'undefined' ? parseFloat(item.uptime.toFixed(2)) : 0)


																													}
																												})
																											}
																											XAxisInterval={'hours'}
																											// chartColor={item.is_down === false ? this.state.statusPageChartColor : '#F44336'}
																											chartColor={this.state.statusPageChartColor}
																											hashId={`${selectedRangeDate}_${index}`}
																											height="80"
																											margin={{
																												top: 5,
																												bottom: -13,
																												left: 0,
																												right: 0
																											}}

																										/>
																									}
																									{
																										(typeof item.chart_uptime !== 'undefined' && item.chart_uptime === true &&
																											typeof item.chart_responsetime !== 'undefined' && item.chart_responsetime === true) &&
																										<div className="separator"></div>
																									}
																									{
																										typeof item.chart_responsetime !== 'undefined' && item.chart_responsetime === true &&
																										<RechartAreaChart
																											chartType='responsetime'
																											data={
																												this.state.pings_1d.find(x => x.monitorId === item.id).data.map(item => {

																													return {
																														date: item.date,
																														value: (typeof item !== 'undefined' ? parseFloat(item.responsetime.toFixed(2)) : 0)


																													}
																												})
																											}
																											XAxisInterval={'hours'}
																											// chartColor={item.is_down === false ? this.state.statusPageChartColor : '#F44336'}
																											chartColor={this.state.statusPageChartColor}
																											hashId={`${selectedRangeDate}_${index}`}
																											height="80"
																											margin={{
																												top: 5,
																												bottom: -13,
																												left: 0,
																												right: 0
																											}}
																										/>
																									}
																								</>
																								: <div className="no-data"><span>Data in progress, please try again later</span></div>
																						)
																				}
																			</div>
																			<div className={`check-item__status-wrapper ${item.chart_indices === false ? 'hide' : ''} `}>
																				{
																					typeof averagePingsDataPerMonitor !== 'undefined' && typeof averagePingsDataPerMonitor[item.id] !== 'undefined' && typeof averagePingsDataPerMonitor[item.id]['1d'] !== 'undefined' &&
																					<>
																						<div className="ci-avg_responsetime">
																							<label htmlFor="">Avg. ResponseTime</label>
																							<span>{`${averagePingsDataPerMonitor[item.id]['1d'].responsetime.toFixed(2)} ms`}</span>
																						</div>
																						<div className="ci-avg_uptime">
																							<label htmlFor="">Avg. Uptime</label>
																							<span>{`${averagePingsDataPerMonitor[item.id]['1d'].uptime.toFixed(2)} %`}</span>
																						</div>
																					</>
																				}
																			</div>
																		</div>
																	)
																})
															}
														</div>
													}

													<div className={`tab--365d ${typeof this.state.availableDateRanges !== 'undefined' && this.state.availableDateRanges.find(x => x.selected === true).nb_days === 1 ? 'hide' : ''}`}>
														{
															(selectedRangeDate >= 7 || this.state.pings_7d.length > 0 || this.state.pings_30d.length > 0 || this.state.pings_91d.length > 0 || this.state.pings_365d.length > 0)
															&& this.state.monitorItems.map((item, index) => {



																return (
																	<div key={index} className="check-item white-card">
																		<div className="check-item__main-info">
																			<div className="check-item__status">
																				<StatusIndicator
																					status={
																						item.is_down === true
																							? 'danger'
																							: 'success'
																					}
																					title={``} />
																			</div>
																			<div className="check-item__name">{item.name}</div>
																			<div className="check-item__state">
																				{
																					item.is_down === true
																						? <div className="ci-state is-down" >Partial Outage</div>
																						: <div className="ci-state " >Operational</div>
																				}
																			</div>
																		</div>
																		<div className={`check-item__chart ${item.chart_uptime === false && item.chart_responsetime === false ? 'hide' : ''}`}>
																			{
																				this.state.isLoadingMonitors[item.id]
																					? <div className="loading-wrapper "><LoadingSpinner position="center" size="small" /></div>
																					: typeof selectedRangePings !== 'undefined' && selectedRangePings.length > 0 &&
																					(

																						typeof selectedRangePings.find(x => x.monitorId === item.id) !== 'undefined' &&
																							typeof selectedRangePings.find(x => x.monitorId === item.id).data !== undefined
																							?
																							<>
																								{
																									typeof item.chart_uptime !== 'undefined' && item.chart_uptime === true &&
																									(
																										selectedRangeOption !== null && selectedRangeOption.chart_type.uptime === 'bar'
																											? <RechartBarChart
																												chartType='uptime'
																												data={
																													selectedRangePings.find(x => x.monitorId === item.id).data.map(itemPing => {
																														let related_incidents_tmp = [];
																														related_incidents_tmp = this.state.outageRanges
																															?.filter(x => x.monitor_id === item.id)[0]?.outage
																															?.filter(x => {
																																return moment(moment(itemPing.date).format("YYYY-MM-DD")).isBetween(moment(x.from).format("YYYY-MM-DD"), moment(x.to).format("YYYY-MM-DD"), undefined, '[]')
																															}
																															)[0];
																														// ? Calcul the outage time in the selected day 
																														let total_outage_time = 0
																														let startTimeLocalOutage = null
																														let endTimeLocalOutage = null
																														if (typeof related_incidents_tmp !== 'undefined' && related_incidents_tmp !== null) {

																															if (moment(related_incidents_tmp.from).unix() > moment(itemPing.date).unix())
																																startTimeLocalOutage = moment(related_incidents_tmp.from).unix()
																															else startTimeLocalOutage = moment(itemPing.date).unix()

																															if (moment(itemPing.date).add(24, 'hours').unix() > moment(related_incidents_tmp.to).unix())
																																endTimeLocalOutage = moment(related_incidents_tmp.to).unix()
																															else endTimeLocalOutage = moment(itemPing.date).add(24, 'hours').unix()



																															total_outage_time = endTimeLocalOutage - startTimeLocalOutage;
																														}

																														// ? Set related incidents 			
																														related_incidents_tmp = related_incidents_tmp?.related_incidents;
																														const uptimeValue = parseFloat(itemPing.uptime.toFixed(2)) //Math.random() * (100 - 100) + 100
																														return {
																															date: itemPing.date,
																															value: typeof itemPing !== 'undefined'
																																? (
																																	total_outage_time > 0
																																		? 100 // ((total_outage_time/3600) *100 ) / 24
																																		: uptimeValue //

																																)
																																: 0,
																															incidents: total_outage_time > 0 ? related_incidents_tmp : [],
																															uptime: typeof itemPing !== 'undefined' ? uptimeValue : 0,//parseFloat(itemPing.uptime.toFixed(2)) : 0 , 
																															outage: typeof itemPing !== 'undefined' ? total_outage_time / 3600 : 0,
																															is_outage: total_outage_time > 0 ? true : false,



																														}
																													})
																												}
																												XAxisInterval={'days'}
																												// chartColor={item.is_down === false ? this.state.statusPageChartColor : '#F44336'}
																												chartColor={this.state.statusPageChartColor}
																												hashId={`${selectedRangeDate}_${index}`}
																												height="80"
																												margin={{
																													top: 5,
																													bottom: -13,
																													left: 0,
																													right: 0
																												}}
																												avgUptime={averagePingsDataPerMonitor[item.id][selectedRangeDate + 'd'].uptime.toFixed(2)}//
																											/>
																											: <RechartAreaChart
																												chartType='uptime'
																												data={
																													selectedRangePings.find(x => x.monitorId === item.id).data.map(itemPing => {
																														// let related_incidents_tmp = [];

																														// if(typeof itemPing.related_incidents!=='undefined' && itemPing.related_incidents!==null ) 
																														// 	itemPing.related_incidents.filter( incident => incident!==null && incident.length > 0 ).map( element => element.map( obj => related_incidents_tmp.push(obj) ) )
																														let related_incidents_tmp = [];
																														related_incidents_tmp = this.state.outageRanges
																															?.filter(x => x.monitor_id === item.id)[0]?.outage
																															?.filter(x => {
																																return moment(moment(itemPing.date).format("YYYY-MM-DD")).isBetween(moment(x.from).format("YYYY-MM-DD"), moment(x.to).format("YYYY-MM-DD"), undefined, '[]')
																															}
																															)[0];


																														// ? Calcul the outage time in the selected day 
																														let total_outage_time = 0
																														let startTimeLocalOutage = null
																														let endTimeLocalOutage = null
																														if (typeof related_incidents_tmp !== 'undefined' && related_incidents_tmp !== null) {

																															if (moment(related_incidents_tmp.from).unix() > moment(itemPing.date).unix()) startTimeLocalOutage = moment(related_incidents_tmp.from).unix()
																															else startTimeLocalOutage = moment(itemPing.date).unix()

																															if (moment(itemPing.date).add(24, 'hours').unix() > moment(related_incidents_tmp.to).unix()) endTimeLocalOutage = moment(related_incidents_tmp.to).unix()
																															else endTimeLocalOutage = moment(itemPing.date).add(24, 'hours').unix()

																															total_outage_time = endTimeLocalOutage - startTimeLocalOutage;
																														}

																														// ? Set related incidents 			
																														related_incidents_tmp = related_incidents_tmp?.related_incidents;
																														return {
																															date: itemPing.date,
																															value: (typeof itemPing !== 'undefined' ? parseFloat(itemPing.uptime.toFixed(2)) : 0),
																															incidents: related_incidents_tmp,
																															outage: total_outage_time / 3600


																														}
																													})
																												}
																												XAxisInterval={'days'}
																												// chartColor={item.is_down === false ? this.state.statusPageChartColor : '#F44336'}
																												chartColor={this.state.statusPageChartColor}
																												hashId={`${selectedRangeDate}_${index}`}
																												height="80"
																												margin={{
																													top: 5,
																													bottom: -13,
																													left: 0,
																													right: 0
																												}}
																											/>
																									)

																								}
																								{
																									(typeof item.chart_uptime !== 'undefined' && item.chart_uptime === true &&
																										typeof item.chart_responsetime !== 'undefined' && item.chart_responsetime === true) &&
																									<div className="separator"></div>
																								}
																								{
																									typeof item.chart_responsetime !== 'undefined' && item.chart_responsetime === true &&
																									<RechartAreaChart
																										chartType='responsetime'
																										data={
																											selectedRangePings.find(x => x.monitorId === item.id).data.map(item => {
																												return {
																													date: item.date,
																													value: (typeof item !== 'undefined' ? parseFloat(item.responsetime.toFixed(2)) : 0)


																												}
																											})
																										}
																										XAxisInterval={'days'}
																										// chartColor={item.is_down === false ? this.state.statusPageChartColor : '#F44336'}
																										chartColor={this.state.statusPageChartColor}
																										hashId={`${selectedRangeDate}_${index}`}
																										height="80"
																										margin={{
																											top: 5,
																											bottom: -13,
																											left: 0,
																											right: 0
																										}}
																									/>
																								}
																							</>
																							: <div className="no-data"><span>Data in progress, please try again later</span></div>
																					)
																			}
																		</div>
																		<div className={`check-item__status-wrapper ${item.chart_indices === false ? 'hide' : ''} ${selectedRangeOption !== null && selectedRangeOption.chart_type.uptime === 'bar' ? 'status-for-uptime-bar' : ''}`}>
																			{
																				typeof averagePingsDataPerMonitor !== 'undefined' && typeof averagePingsDataPerMonitor[item.id] !== 'undefined' && typeof averagePingsDataPerMonitor[item.id][selectedRangeDate + 'd'] !== 'undefined' &&
																				<>
																					<div className="ci-avg_responsetime">
																						<label htmlFor="">Avg. ResponseTime</label>
																						<span>{`${averagePingsDataPerMonitor[item.id][selectedRangeDate + 'd'].responsetime.toFixed(2)} ms`}</span>
																					</div>
																					<div className="ci-avg_uptime">
																						<label htmlFor="">Avg. Uptime</label>
																						<span>{`${averagePingsDataPerMonitor[item.id][selectedRangeDate + 'd'].uptime.toFixed(2)} %`}</span>
																					</div>
																				</>
																			}
																		</div>
																	</div>
																)
															})
														}
													</div>

												</>
										}
									</div>
							}
						</div>
					</div>
				</div>
				<Footer />

			</React.Fragment>
		)
	}
}

